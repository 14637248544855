import {Injectable} from '@angular/core';

import {SafeImageInterface, SafeImageStoreInterface} from './safe-image.interface';

@Injectable({
  providedIn: 'root'
})
export class SafeImageService {
  private imageStore: SafeImageStoreInterface = {};

  setImageToStore(image: SafeImageInterface): void {
    if (!this.getImageFromStore(image.name)) {
      this.imageStore[image.name] = image;
    }
  }

  getImageFromStore(name: string): SafeImageInterface {
    return this.imageStore[name];
  }

  clearImageStore(): void {
    this.imageStore = {};
  }
}
