export const localToken = 'arimed-token';

export const dateMomentFormat = 'DD/MM/YYYY';
export const dateDoctorFormat = 'YYYY.MM.DD';
export const dateConsultationFormat = 'YYYY.MM.DD HH:mm:ss';
export const googlePlayLink = 'https://play.google.com/store/apps/details?id=com.doctors4ua.patients';
export const appStoreLink = 'https://apps.apple.com/gb/app/doctors4ua-patient/id1615379386';

export const CUSTOM_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: dateMomentFormat,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
