
<div class="lang" *ngIf="!windowSizeConfig.isTablet">
  <app-language-select></app-language-select>
</div>

<div
  class="header_nav sidebar_nav"
  *ngIf="!windowSizeConfig.isNoteBookDesktop"
  (click)="toggleSidenav.emit()"
>
  <div class="nav_item">
    <app-link-doctors></app-link-doctors>
  </div>

  <div class="nav_item">
    <app-link-consultations></app-link-consultations>
  </div>

  <div class="nav_item">
    <app-link-tracker></app-link-tracker>
  </div>

  <div class="nav_item">
    <app-link-recipes></app-link-recipes>
  </div>

  <div class="nav_item">
    <app-link-measurements></app-link-measurements>
  </div>

  <div class="nav_item" *ngIf="hasPayment$ | async">
    <app-wallet></app-wallet>
  </div>
</div>


<div class="sidebar_action">
  <div class="footer_info">

    <a [href]="termsOfUse" target="_blank">
      <mat-icon fontSet="material-icons-outlined">person</mat-icon>
      {{ 'Обробка персональних даних' | translate }}
    </a>
  </div>
  <div class="footer_apps">
    <app-apps-links *ngIf="(activeClinic$ | async).id === clinicIds.doctors4ua"></app-apps-links>
  </div>
</div>
