export function getObjectUrlParams(filter: object) {
  return Object.entries(filter).reduce((acc, [key, value]) => {
    if (value !== null && value !== '' && value !== undefined) {
      return {...acc, [key]: value};
    }

    return acc;
  }, {});
}

export function getObjectUrlParamsWithArrays(filter: object) {
  return Object.entries(filter).reduce((acc, [key, value]) => {
    if (value !== null && value !== '' && value !== undefined) {
      if (!Array.isArray(value)) {
        return {...acc, [key]: value};
      }

      value.forEach((el, i) => {
        const keyVal = `${[key][0]}[${i}]`;
        acc = {...acc, [keyVal]: el};
      });

      return acc;
    }

    return acc;
  }, {});
}

export function getStringUrlParams(filter: object): string {
  const params = Object.entries(filter).reduce((acc, [key, value]) => {
    if (value !== null && value !== '' && value !== undefined) {
      if (Array.isArray(value) && value.length) {
        return `${acc}${stringPhpArrayParams(key, value)}`;
      }
      return `${acc}&${key}=${value}`;
    }
    return acc;
  }, '');

  return params.slice(1, params.length);
}

export function stringPhpArrayParams(key: string, data: any[]): string {
  let arrayParams = '';
  data.forEach((item, index) => {
    arrayParams += `&${key}[${index}]=${item}`;
  });
  return arrayParams;
}

export function getObjectParamsFormUrlString(urlParams: string): { [key: string]: string } {
  const objectUrlParams = {};
  const arrayUrlParams = urlParams.split('&');
  let field = null;

  for (let i = 0; i < arrayUrlParams.length; i++) {
    field = arrayUrlParams[i].split('=');
    objectUrlParams[field[0]] = field[1];
  }

  return objectUrlParams;
}
