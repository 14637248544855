import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';

import {ProfilePreNameComponent} from './profile-pre-name.component';
import {ValidatorMessageModule} from '../validator-message/validator-message.module';
import {ProfilePreNameService} from '../profile-pre-name/profile-pre-name.service';
import {TranslateModule} from "@core/pipes/translate/translate.module";


@NgModule({
  declarations: [
    ProfilePreNameComponent
  ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatInputModule,
        ValidatorMessageModule,
        TranslateModule
    ],
  providers: [
    ProfilePreNameService
  ],
  exports: [
    ProfilePreNameComponent
  ]
})
export class ProfilePreNameModule {
}
