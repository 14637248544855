import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OverlayModule} from '@angular/cdk/overlay';
import {PortalModule} from '@angular/cdk/portal';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';

import {PromptModalComponent} from './components/prompt-modal/prompt-modal.component';
import {StartEducationComponent} from './components/start-education/start-education.component';
import {EducationStepComponent} from './components/education-step/education-step.component';
import {ModalHeaderModule} from '../modals/modal-header/modal-header.module';
import {EducationComponent} from './education.component';


@NgModule({
  declarations: [
    PromptModalComponent,
    StartEducationComponent,
    EducationStepComponent,
    EducationComponent
  ],
  imports: [
    CommonModule,
    OverlayModule,
    PortalModule,
    ModalHeaderModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule
  ],
  exports: [
    EducationComponent,
    EducationStepComponent
  ]
})
export class EducationModule {
}
