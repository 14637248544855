<div class="wallet">
  <div class="wallet_icon">
    <img src="./assets/images/payment.svg" alt="payment">
  </div>
  <div class="wallet_info">
    <div class="wallet_state">
      <span class="amount">{{ wallet?.amount }} {{ 'балів' | translate }}</span>
    </div>
    <span class="wallet_action" (click)="refillWallet()">{{ 'Поповнити рахунок' | translate }}</span>
  </div>
</div>
