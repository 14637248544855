import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {finalize} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {FileUploaderService} from '@core/services/file-uploader.service';
import {FilePreviewInterface} from '@entry-components/file-preview/file-preview.interface';
import {FileExtensionsEnum, FileTypesEnum} from '@core/enums/file-types.enum';

@UntilDestroy()
@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent implements OnInit {
  fileTypes = FileTypesEnum;
  fileExtensions = FileExtensionsEnum;

  file;

  isPDFFile = this.checkPDF();

  isError = false;

  isLoading = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FilePreviewInterface,
    public dialogRef: MatDialogRef<FilePreviewComponent>,
    private fileUploaderService: FileUploaderService,
    private sanitizer: DomSanitizer,
  ) {
  }

  ngOnInit(): void {
    if (this.data.localUrl && this.data.fileName) {
      this.isError = true;
      this.isLoading = false;

      return;
    }

    if (this.data.localUrl) {
      this.file = {url: this.data.localUrl};

      if (!this.isPDFFile) {
        this.isLoading = false;
      }

      this.file.secureUrl = this.sanitizer
        .bypassSecurityTrustResourceUrl(this.data.localUrl as string);

      return;
    }

    this.isPDFFile ? this.buildPdfByToken() : this.buildImageByToken();
  }

  documentLoaded(): void {
    this.isLoading = false;
  }

  private buildImageByToken(): void {
    this.fileUploaderService
      .buildImageByToken(this.data.fileName, this.data.storage)
      .pipe(
        finalize(() => this.isLoading = false),
        untilDestroyed(this)
      )
      .subscribe(
        (result: SafeUrl) => this.file = {url: result},
        () => this.isError = true
      );
  }

  private buildPdfByToken(): void {
    this.fileUploaderService
      .buildPdfByToken(this.data.fileName, this.data.storage)
      .pipe(untilDestroyed(this))
      .subscribe(
        (result) => this.file = result,
        () => this.isError = true
      );
  }

  private checkPDF(): boolean {
    return this.data.type === this.fileTypes.PDF || this.data.type === this.fileExtensions.PDF;
  }
}
