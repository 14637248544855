import {Component, DestroyRef, OnDestroy, OnInit} from '@angular/core';
import {
  ActivatedRoute, NavigationEnd,
  Params,
  Router
} from '@angular/router';
import {filter, take} from 'rxjs/operators';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

import {InternalUrlService} from '@core/services/internal-url.service';
import {WindowSizeConfigInterface} from '@core/interfaces/window-size-config.interface';
import {WindowSizeService} from '@core/services/window-size.service';
import {NearestConsultationService} from '@core/services/nearest-consultation.service';
import {CentrifugoSocketService} from "@core/services/centrifugo-socket.service";
import {NetworkStateTypesEnum} from "@shared-modules/network-state/network-state-types.enum";
import {NetworkStateService} from "@shared-modules/network-state/network-state.service";
import {MessagesCountsService} from "@core/services/messages-counts.service";

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent implements OnInit, OnDestroy {
  windowSizeConfig: WindowSizeConfigInterface;
  showNearestConsultation$ = this.nearestConsultationService.showNearestConsultation$;

  hideElementsByRoute = false;
  isResultPage = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private internalUrlService: InternalUrlService,
    private networkStateService: NetworkStateService,
    private nearestConsultationService: NearestConsultationService,
    private windowSizeService: WindowSizeService,
    private centrifugoSocketService: CentrifugoSocketService,
    private messagesCountsService: MessagesCountsService,
    private destroyRef: DestroyRef
  ) {
    this.handleWindowSizeConfig();
    this.getCurrentRoute();
  }

  ngOnInit(): void {
    this.handleRouteParamsSubject();
    this.manageNearestConsultation();

    this.centrifugoSocketService.socketInit();
    this.watchNetworkState();

    this.messagesCountsService.getUnreadMessages()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.centrifugoSocketService.socketDisconnect();
  }

  private handleRouteParamsSubject(): void {
    this.route.queryParams
      .pipe(
        take(1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((params: Params) => {
        if (!params) {
          return;
        }

        if (params.internalurl) {
          const internalUrlParams = this.internalUrlService
            .buildInternalUrlParams(params);

          this.router.navigateByUrl(internalUrlParams)
            .then(() => this.internalUrlService.stringUrlParams$.next(''));
        }
      });
  }

  private handleWindowSizeConfig(): void {
    this.windowSizeService.windowSizeConfig$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((windowSizeConfig: WindowSizeConfigInterface) => {
        this.windowSizeConfig = windowSizeConfig;
      });
  }

  private manageNearestConsultation(): void {
    const localValue = this.nearestConsultationService.getLocalSettings();
    if (!localValue) {
      this.nearestConsultationService.setLocalSetting(true);
      return;
    }

    this.nearestConsultationService.setLocalSetting(localValue === 'true');
  }

  private getCurrentRoute(): void {
    this.router.events
      .pipe(
        filter((e: NavigationEnd) => e instanceof NavigationEnd),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((navigation) => {
        this.hideElementsByRoute = navigation.url.includes('/chat');
        this.isResultPage = navigation.url.includes('consultation-result');
      });
  }

  private watchNetworkState(): void {
    this.networkStateService.networkConnectionState$
      .pipe(
        filter(r => !!r),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(res => {
        res === NetworkStateTypesEnum.Online
          ? this.centrifugoSocketService.socketInit()
          : this.centrifugoSocketService.socketDisconnect();
      });
  }
}
