import {Component, OnInit} from '@angular/core';
import {filter, mergeMap} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import * as moment from 'moment';
import {interval} from 'rxjs';

import {ProfileService} from '@profile/profile.service';
import {ConsultationsService} from '@consultations/consultations.service';
import {
  ConsultationsListFilter
} from '@consultations/interfaces/consultation-list-filter.interface';
import {ConsultationStatusesEnum} from '@consultations/enums/consultation-statuses.enum';
import {Consultation} from '@consultations/interfaces/consultation.interface';
import {NearestConsultationService} from '@core/services/nearest-consultation.service';
import {ClinicIdsEnum} from '@core/enums/clinic-ids.enum';
import {TypeCommunicationEnum} from '@core/enums/type-communication.enum';

@UntilDestroy()

@Component({
  selector: 'app-nearest-consultation',
  templateUrl: './nearest-consultation.component.html',
  styleUrls: ['./nearest-consultation.component.scss']
})
export class NearestConsultationComponent implements OnInit {
  params: ConsultationsListFilter = {
    page: 1,
    perPage: 1,
    use_ended_at: 1,
    direction: 'ASC',
    field: 'start_time',
    from_end: moment().utc().unix(),
    statuses: [ConsultationStatusesEnum.Prepare, ConsultationStatusesEnum.Started]
  };

  consultation: Consultation;
  interval = 1;
  hideTimer = false;
  startDays: number;
  startHours: number;
  startMinutes: number;
  timerSub: any;
  clinicIds = ClinicIdsEnum;
  activeClinic$ = this.profileService.activeClinic$;
  typeCommunicationEnum = TypeCommunicationEnum;

  constructor(
    private profileService: ProfileService,
    private nearestConsultationService: NearestConsultationService,
    private consultationsService: ConsultationsService,
  ) {
  }

  ngOnInit(): void {
    this.getNearestConsultation();
    this.updateNearestConsultation();
  }

  close(): void {
    this.nearestConsultationService.setLocalSetting(false);
  }

  startConsultation(consultation: Consultation): void {
    this.consultationsService.onStartConsultation(consultation);
  }

  private getNearestConsultation(refreshTimer = false): void {
    this.profileService.profile$
      .pipe(
        filter(r => !!r),
        mergeMap(profile => {
          this.params.patient_id = profile.id;
          return this.consultationsService.getConsultationsList(this.params);
        }),
        untilDestroyed(this)
      )
      .subscribe(res => {
        this.consultation = res.data.length ? res.data[0] : null;
        if (this.consultation) {
          this.timeToStartConsultation(this.consultation, refreshTimer);
        }
      });
  }

  private timeToStartConsultation(consultation: Consultation, clearTimer = false): void {
    if (consultation.status === ConsultationStatusesEnum.Started) {
      this.hideTimer = true;
      return;
    }

    if (this.timerSub && clearTimer) {
      this.timerSub.unsubscribe();
    }

    this.timerSub = interval(1000)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const duration: number = Math.floor(moment(consultation.start_time).diff(moment(), 'seconds'));
        const counter = moment.duration(duration - this.interval, 'seconds');

        if (counter.asMinutes() < 1 && consultation.type_communication === TypeCommunicationEnum.Offline) {
          this.getNextConsultation(consultation);
          return;
        }

        if (counter.asMinutes() <= 0) {
          this.hideTimer = true;
          this.timerSub.unsubscribe();
          return;
        }

        this.startDays = counter.days();
        this.startHours = counter.hours();
        this.startMinutes = counter.minutes();
      });
  }

  private updateNearestConsultation(): void {
    this.nearestConsultationService.updateNearestConsultation$
      .pipe(
        filter(b => b),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.getNearestConsultation(true);
        this.nearestConsultationService.updateNearestConsultation$.next(null);
      });
  }

  private getNextConsultation(consultation: Consultation): void {
    const isStartByTime = moment().add(1, 'minutes').unix() > moment(consultation.start_time).unix();

    if (!isStartByTime) {
      return;
    }

    this.params.from = moment(consultation.end_time).unix();
    this.nearestConsultationService.updateNearestConsultation$.next(true);
  }
}
