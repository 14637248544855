import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {NumberOnlyDirective} from './numbers-only.derective';


@NgModule({
  declarations: [
    NumberOnlyDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    NumberOnlyDirective
  ]
})
export class NumbersOnlyModule {
}
