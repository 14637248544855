import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {ResolvePaymentService} from '../../../../payment/services/resolve-payment.service';
import {unsubscribe} from '@core/utils/unsubscriber';
import {WalletInterface} from '@core/interfaces/wallet/wallet.interface';
import {WalletService} from '@core/services/wallet.service';

@UntilDestroy()

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss']
})
export class WalletComponent implements OnInit {
  wallet: WalletInterface;

  constructor(
    private router: Router,
    private walletService: WalletService,
    private paymentService: ResolvePaymentService,
  ) {
  }

  ngOnInit(): void {
    this.getWallet();

    this.handleRouterEventsSubject();
    this.handleWalletUpdateSubject();
  }

  refillWallet(): void {
    this.paymentService.refillWallet();
  }

  private handleRouterEventsSubject(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        untilDestroyed(this)
      )
      .subscribe(() => this.getWallet());
  }

  private handleWalletUpdateSubject(): void {
    this.walletService.walletUpdated$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.getWallet());
  }

  private getWallet(): void {
    this.walletService.getWallet()
      .pipe(untilDestroyed(this))
      .subscribe((result: WalletInterface) => {
        this.wallet = result;
      });
  }
}
