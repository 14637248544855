<div class="footer">
  <div class="footer_content">
    <div class="footer_info">
      <a [href]="termsOfUse" target="_blank">
        <mat-icon fontSet="material-icons-outlined">person</mat-icon>
        {{ 'Обробка персональних даних' | translate }}
      </a>
    </div>
    <div class="footer_apps">
      <app-apps-links *ngIf="(activeClinic$ | async).id === clinicIds.doctors4ua"></app-apps-links>
    </div>
  </div>
</div>
