export enum FileTypesEnum {
  PDF = 'application/pdf',
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  PNG = 'image/png'
}

export enum FileExtensionsEnum {
  PDF = 'pdf',
  JPG = 'jpg',
  JPEG = 'jpeg',
  PNG = 'png'
}
