import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatBadgeModule} from '@angular/material/badge';
import {SpinnerModule} from '@shared-modules/spinner/spinner.module';
import {PdfViewerModule} from 'ng2-pdf-viewer';

import {AppLayoutComponent} from './app-layout.component';
import {SidenavComponent} from './components/sidenav/sidenav.component';
import {EducationModule} from '../../education/education.module';
import {SafeImageModule} from '../../safe-image/safe-image.module';
import {NotificationsModule} from '../../notifications/notifications.module';
import {
  LinkConsultationsComponent
} from './components/link-consultations/link-consultations.component';
import {LinkDialoguesComponent} from './components/link-dialogues/link-dialogues.component';
import {LinkAnalyzesComponent} from './components/link-analyzes/link-analyzes.component';
import {LinkDoctorsComponent} from './components/link-doctors/link-doctors.component';
import {HeaderComponent} from './components/header/header.component';
import {WalletComponent} from './components/wallet/wallet.component';
import {ClinicComponent} from './components/clinic/clinic.component';
import {LogoComponent} from './components/logo/logo.component';
import {ProfileHeaderComponent} from './components/profile-header/profile-header.component';
import {ClinicsService} from '../../../clinics/services/clinics.service';
import {AppsLinksComponent} from './components/apps-links/apps-links.component';
import {FooterComponent} from './components/footer/footer.component';
import {
  NearestConsultationComponent
} from './components/nearest-consultation/nearest-consultation.component';
import {ImagePathModule} from '@core/pipes/image-path/image-path.module';
import {TranslateModule} from '@core/pipes/translate/translate.module';
import {LinkTrackerComponent} from './components/link-tracker/link-tracker.component';
import {FilePreviewComponent} from '@entry-components/file-preview/file-preview.component';
import {LinkRecipesComponent} from './components/link-recipes/link-recipes.component';
import {RecipesComponent} from '../../../dashboard/recipes/recipes.component';
import {SharedModule} from '../../../shared/shared.module';
import {
  LinkMeasurementsComponent
} from './components/link-measurements/link-measurements.component';
import {MessagesService} from "../../../dashboard/dialogues/services/messages.service";
import {
  LanguageSelectComponent
} from "@shared-modules/layouts/app-layout/components/language-select/language-select.component";


@NgModule({
  declarations: [
    AppLayoutComponent,
    SidenavComponent,
    HeaderComponent,
    WalletComponent,
    ClinicComponent,
    FilePreviewComponent,
    ProfileHeaderComponent,
    LogoComponent,
    LinkConsultationsComponent,
    LinkDialoguesComponent,
    LinkAnalyzesComponent,
    LinkDoctorsComponent,
    AppsLinksComponent,
    FooterComponent,
    NearestConsultationComponent,
    LinkTrackerComponent,
    LinkRecipesComponent,
    RecipesComponent,
    LinkMeasurementsComponent
  ],
  imports: [
    CommonModule,
    MatSidenavModule,
    RouterModule,
    MatListModule,
    EducationModule,
    SafeImageModule,
    NotificationsModule,
    MatButtonModule,
    MatIconModule,
    MatBadgeModule,
    ImagePathModule,
    TranslateModule,
    SpinnerModule,
    PdfViewerModule,
    SharedModule,
    LanguageSelectComponent
  ],
  exports: [
    AppLayoutComponent
  ],
  providers: [
    ClinicsService,
    MessagesService
  ]
})
export class AppLayoutModule {
}
