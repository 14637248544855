import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {combineLatest} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {ModalCostInterface} from '../../../payment/interfaces/modal-cost.interface';
import {Patient} from '@core/interfaces/patient.interface';
import {ProfileService} from '@profile/profile.service';
import {PaymentService} from '@shared-modules/payment/services/payment.service';


@UntilDestroy()

@Component({
  selector: 'app-modal-cost',
  templateUrl: './modal-cost.component.html',
  styleUrls: ['./modal-cost.component.scss']
})
export class ModalCostComponent implements OnInit {
  profile: Patient;
  hasPayment: boolean;

  title: string | string[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalCostInterface,
    public dialogRef: MatDialogRef<ModalCostComponent>,
    private profileService: ProfileService,
    private paymentService: PaymentService
  ) {
  }

  ngOnInit(): void {
    this.getProfileAndPayment();
  }

  prenameResolve(event?: boolean): void {
    if (!this.hasPayment) {
      this.dialogRef.close(event);
      return;
    }

    this.title = [
      'Вартість консультації',
      `${this.data.price}`,
      'балів'
    ];
  }

  private getProfileAndPayment(): void {
    combineLatest([
      this.profileService.profile$,
      this.paymentService.hasPayment$
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([profile, hasPament]) => {
        this.profile = profile;
        this.hasPayment = hasPament;

        if (!this.hasPayment || (this.hasPayment && !this.profile.name)) {
          this.title = 'Почати консультацію?';
          return;
        }

        this.title = [
          'Вартість консультації',
          `${this.data.price}`,
          'балів'
        ];
      });
  }
}
