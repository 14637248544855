import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {environment} from '../../../environments/environment';
import {ProfileResponse} from '@profile/interfaces/profile-response.interface';

@Injectable()
export class ProfilePreNameService {
  private apiUrl = `${environment.apiUrl}/profile`;

  constructor(private http: HttpClient) {
  }

  setProfilePreName(params: {name: string, surname: string}): Observable<ProfileResponse> {
    return this.http.post<ProfileResponse>(this.apiUrl, params);
  }
}
