export enum NotificationTypesEnum {
  PatientRecommendationDone = 'patient_recommendation_done',
  UnsubscribeWaitingArea = 'unsubscribe_waiting_area',
  SubscribeWaitingArea = 'subscribe_waiting_area',
  NewDiscussionMessage = 'new_discussion_message',
  AddMoney = 'add_money',
  PaymentFail = 'payment_fail',
  AddMedicationSchedule = 'add_medication_schedule',
  TimeToMedicationPoint = 'time_to_do_medication_point',
  CancelConsultationByDoctor = 'cancel_consultation_doctor'
}
