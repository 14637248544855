import {Pipe, PipeTransform} from '@angular/core';
import {ProfileService} from '@profile/profile.service';

@Pipe({
  name: 'dateAsAgo',
})
export class DateAsAgoPipe implements PipeTransform {
  values = {
    en: [' sec', ' min', ' h', ' day'],
    ru: [' сек', ' мин', ' ч', ' день'],
    uk: [' сек', ' хв', ' год', ' день']
  };

  now = {
    en: 'now',
    ru: 'сейчас',
    uk: 'зараз'
  };

  days = {
    en: ' days',
    ru: ' дней',
    uk: ' днів'
  };

  daysLessFive = {
    en: ' days',
    ru: ' дня',
    uk: ' дні'
  };

  constructor(private profileService: ProfileService) {
  }

  transform(value: any): string {
    const lang = this.profileService.language$.getValue();

    let time = (Date.now() - Date.parse(value)) / 1000;
    const oneDay = 24 * 60 * 60;

    if (Math.floor(time / oneDay) > 7) {
      return value.format('DD.MM.YY');
    }

    if (time < 60) {
      return this.now[lang];
    }

    const divider = [60, 60, 24, 30, 12];
    const string = this.values[lang];


    if (Math.floor(time / oneDay) > 1 && Math.floor(time / oneDay) < 5) {
      string[string.length - 1] = this.daysLessFive[lang];
    }

    if (Math.floor(time / oneDay) >= 5) {
      string[string.length - 1] = this.days[lang];
    }

    let i;
    for (i = 0; Math.floor(time / divider[i]) > 0; i++) {
      time /= divider[i];
    }

    return `${Math.floor(time)} ${string[i]}`;
  }
}
