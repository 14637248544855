import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";

import {
  ApiUnreadMessagesInterface
} from "../../shared-modules/layouts/app-layout/interfaces/sidebar-counters.interface";

@Injectable({
  providedIn: 'root'
})
export class MessagesCountsService {
  unreadMessagesCount$: BehaviorSubject<number | null> = new BehaviorSubject(0);

  private apiUrl = `${environment.apiUrl}/discussion-message`;

  constructor(private http: HttpClient) {
  }

  getUnreadMessages(): Observable<void> {
    return this.http
      .get<ApiUnreadMessagesInterface>(`${this.apiUrl}/count`)
      .pipe(
        map((result: ApiUnreadMessagesInterface) => this.unreadMessagesCount$.next(result.count_not_read_message))
      );
  }

  readAllMessages(id: number): Observable<void> {
    return this.http
      .get(`${this.apiUrl}/read-all?discussion_id=${id}`)
      .pipe(
        map((result: ApiUnreadMessagesInterface) => this.unreadMessagesCount$.next(result.count_not_read_message))
      )
  }
}
