import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {filter} from 'rxjs/operators';
import {Subscription} from 'rxjs';

import {unsubscribe} from '@core/utils/unsubscriber';

@Component({
  selector: 'app-doctor-network-error',
  templateUrl: './doctor-network-error.component.html',
  styleUrls: ['./doctor-network-error.component.scss']
})
export class DoctorNetworkErrorComponent implements OnInit, OnDestroy {
  chatId: string;

  private subscriptions: Subscription[] = [];

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params
      .pipe(filter((params: Params) => params.id))
      .subscribe((params: Params) => (this.chatId = params.id));
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }
}
