import {ModalImagesEnum} from '../enums/modal-images.enum';

export const ModalImagesConst = [
  {
    type: ModalImagesEnum.base,
    image: 'heart.svg'
  },
  {
    type: ModalImagesEnum.attention,
    image: 'attention.svg'
  },
  {
    type: ModalImagesEnum.confirm,
    image: 'confirm.svg'
  },
  {
    type: ModalImagesEnum.recommendation,
    image: 'recommendation.svg'
  }
];
