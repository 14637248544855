import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {ModalInfoInterface} from './modal-info.interface';
import {ModalImagesConst} from '@core/constants/modal-images.const';
import {PaymentService} from '@shared-modules/payment/services/payment.service';

@Component({
  selector: 'app-modal-info',
  templateUrl: './modal-info.component.html'
})
export class ModalInfoComponent implements OnInit {
  image: string;

  hasPayment$ = this.paymentService.hasPayment$;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalInfoInterface,
    private paymentService: PaymentService
  ) {
  }

  ngOnInit(): void {
    this.image = ModalImagesConst.find(el => el.type === this.data.imageType).image;
  }
}
