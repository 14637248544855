import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SafeImageComponent} from './safe-image.component';

@NgModule({
  declarations: [SafeImageComponent],
  imports: [CommonModule],
  exports: [SafeImageComponent]
})
export class SafeImageModule {}
