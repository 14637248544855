import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AppGuard} from './app.guard';
import {AppLayoutComponent} from '@shared-modules/layouts/app-layout/app-layout.component';
import {
  DoctorNetworkErrorComponent
} from './chat-errors/doctor-network-error/doctor-network-error.component';
import {
  MyNetworkErrorComponent
} from './chat-errors/my-network-error/my-network-error.component';
import {AuthGuard} from '@auth/auth.guard';
import {RecipesComponent} from './dashboard/recipes/recipes.component';

const routes: Routes = [
  {
    path: 'auth',
    canActivate: [AuthGuard],
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path: 'confirm-from-email',
    loadChildren: () => import('./confirm-from-email/confirm-from-email.module')
      .then((m) => m.ConfirmFromEmailModule)
  },
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AppGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'doctors'
      },
      {
        path: 'consultations',
        loadChildren: () =>
          import('./dashboard/consultations/consultations.module').then(
            (m) => m.ConsultationsModule
          )
      },
      {
        path: 'doctors',
        loadChildren: () =>
          import('./dashboard/doctors/doctors.module').then((m) => m.DoctorsModule)
      },
      {
        path: 'files',
        loadChildren: () => import('./dashboard/files/files.module').then((m) => m.FilesModule)
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./dashboard/profile/profile.module').then((m) => m.ProfileModule)
      },
      {
        path: 'dialogues',
        loadChildren: () =>
          import('./dashboard/dialogues/dialogues.module').then((m) => m.DialoguesModule)
      },
      {
        path: 'create-consultation',
        loadChildren: () =>
          import('./dashboard/create-consultation/create-consultation.module')
            .then((m) => m.CreateConsultationModule)
      },
      {
        path: 'clinics',
        loadChildren: () => import('./clinics/clinics.module').then((m) => m.ClinicsModule)
      },
      {
        path: 'waiting-room',
        loadChildren: () =>
          import('./waiting-room/waiting-room.module').then((m) => m.WaitingRoomModule)
      },
      {
        path: 'chat/:id',
        loadChildren: () => import('./chat-room/chat-room.module').then((m) => m.ChatRoomModule)
      },
      {
        path: 'consultation-history/:id',
        loadChildren: () =>
          import('./chat-history/chat-history.module').then((m) => m.ChatHistoryModule)
      },
      {
        path: 'consultation-result/:appointmentId',
        loadChildren: () =>
          import('./consultation-result/consultation-result.module').then(
            (m) => m.ConsultationResultModule
          )
      },
      {
        path: 'doctor-network-error/:id',
        component: DoctorNetworkErrorComponent
      },
      {
        path: 'my-network-error/:id',
        component: MyNetworkErrorComponent
      },
      {
        path: 'my-recipes',
        component: RecipesComponent
      },
      {
        path: 'tracker',
        loadChildren: () => import('./dashboard/tracker/tracker.module').then(m => m.TrackerModule)
      },
      {
        path: 'measurements',
        loadChildren: () => import('./dashboard/measurements/measurements.module').then(m => m.MeasurementsModule)
      },
      {
        path: 'not-found',
        loadChildren: () => import('./not-found/not-found.module').then((m) => m.NotFoundModule)
      },
      {
        path: '**',
        loadChildren: () => import('./not-found/not-found.module').then((m) => m.NotFoundModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
