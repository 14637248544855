import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';

import {buildErrorMessage} from '@core/utils/build-error-message';
import {ModalInfoComponent} from '@shared-modules/modals/modal-info/modal-info.component';
import {ModalImagesEnum} from "@core/enums/modal-images.enum";

@Injectable({
  providedIn: 'root'
})
export class ModalInfoService {
  dialogRefSub;

  constructor(public dialog: MatDialog, private router: Router) {
  }

  onSuccess(message: string | string[], imageType?: number, title?: string, router?: string, showPaymentInfo = false): void {
    this.openModal(message, imageType || ModalImagesEnum.base, title || 'Дякуємо!', router, showPaymentInfo);
  }

  onError(error: any, imageType?: number, title?: string, router?: string, showPaymentInfo = false): void {
    this.openModal(buildErrorMessage(error), imageType || ModalImagesEnum.base, title || 'Помилка', router, showPaymentInfo);
  }

  openModal(message: string | string[], imageType: number, title: string, router?: string, showPaymentInfo = false): void {
    this.dialogRefSub?.unsubscribe();

    const dialogRef = this.dialog.open(ModalInfoComponent, {
      data: {imageType, title, message, showPaymentInfo},
      panelClass: ['primary-modal'],
      autoFocus: false
    });

    if (router) {
      this.dialogRefSub = dialogRef.afterClosed()
        .subscribe(() => this.router.navigate([router]));
    }
  }
}
