import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  hasPayment$ = new BehaviorSubject<boolean | null>(null);
  paymentSystems$ = new BehaviorSubject<string[] | null>(null);


  private paymentApiUrl = `${environment.apiUrl}/payments/init`;

  constructor(
    private http: HttpClient
  ) { }

  sendPayment(payment): Observable<any> {
    return this.http.post(`${this.paymentApiUrl}/${payment.type}`, payment.data);
  }
}
