import {Inject, Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {BehaviorSubject, mergeMap, Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';
import {JsonaService} from '@core/services/jsona.service';
import {TranslateService} from "@ngx-translate/core";

import {localToken} from '../../app.constants';
import {Patient} from '@core/interfaces/patient.interface';
import {Crypto} from '@core/classes/crypto.class';
import {buildFormData} from '@core/utils/build-form-data';
import {getObjectUrlParams} from '@core/utils/filter-converter';
import {
  GetProfileParamsInterface,
  ManagePatientParamsInterface
} from '@profile/interfaces/profile-params.interface';
import {SafeImageService} from '@shared-modules/safe-image/safe-image.service';
import {Clinic} from '../../clinics/interfaces/clinic.interface';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  crypto: Crypto = new Crypto();

  profile$ = new BehaviorSubject<Patient>(null);
  activeClinic$ = new BehaviorSubject<Clinic | null>(null);
  language$ = new BehaviorSubject<string | null>(null);

  private getProfileParams: Partial<GetProfileParamsInterface> = {
    relation_email: true,
    relation_profile_bonus: true,
    relation_active_clinic: true,
    with_relation_clinic_addresses: 1
  };

  private apiUrlV2 = `${environment.apiUrlV2}/profile`;

  constructor(
    private http: HttpClient,
    private router: Router,
    private safeImageService: SafeImageService,
    private jsonaService: JsonaService,
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  loadProfile(): Observable<Patient> {
    return this.getProfile(this.getProfileParams)
      .pipe(
        mergeMap((profile: Patient) => {
          if (!profile?.lang) {
            return this.patchLanguage(this.language$.getValue())
          }

          return of(profile);
        }),
        tap((profile: Patient) => {
          this.profile$.next(profile);
          this.language$.next(profile?.lang);
          this.translateService.setDefaultLang(profile?.lang);
          this.activeClinic$.next(profile.active_clinic);

          if (!this.document.body.classList.contains('add')) {
            this.document.body.classList.add('authorized');
          }
        }));
  }

  getProfile(filter: Partial<GetProfileParamsInterface>): Observable<Patient> {
    const params = getObjectUrlParams(filter);

    return this.http.get(this.apiUrlV2, {params})
      .pipe(map((result) => this.jsonaService.deserialize<Patient>(result).data));
  }

  updateProfile(profile: ManagePatientParamsInterface): Observable<any> {
    const params: FormData = buildFormData(getObjectUrlParams(profile));

    return this.http.post(this.apiUrlV2, params)
      .pipe(map((result) => this.jsonaService.deserialize(result).data));
  }

  patchLanguage(lang): Observable<any> {
    const filter = {
      relation_email: 1,
      relation_profile_bonus: 1,
      relation_active_clinic: 1,
      with_relation_clinic_addresses: 1
    };

    const params = getObjectUrlParams(filter);

    return this.http.patch(`${this.apiUrlV2}`, {...params, lang})
      .pipe(map(res => this.jsonaService.deserialize<Patient>(res).data));
  }

  getToken(): string {
    return localStorage.getItem(this.crypto.encrypt(localToken));
  }

  updateToken(res): void {
    if (res.headers) {
      const token = res.headers.get('authorization');

      if (token) {
        this.setTokenLocal(token);
      }
    }
  }

  setTokenLocal(token: string): void {
    localStorage.setItem(this.crypto.encrypt(localToken), token);
  }

  isAuthenticated(): boolean {
    return !!localStorage.getItem(this.crypto.encrypt(localToken));
  }

  logout(): void {
    localStorage.removeItem(this.crypto.encrypt(localToken));
    localStorage.removeItem('showNearest');
    this.profile$.next(null);
    this.safeImageService.clearImageStore();
    this.router.navigate(['/auth/login']);
    this.document.body.classList.remove('authorized');
  }
}
