<div class="modal-wrap">
  <app-modal-header [title]="'Поповнити рахунок'"></app-modal-header>

  <div class="dialog-content">
    <p class="text-center desc">{{ 'Вкажіть суму поповнення 1 бал - 1$' | translate }}</p>

    <div class="payment_head" *ngIf="!hasSelectPayment; else selectPayment">
      <span class="payment_title">{{ 'Платіжна система:' | translate }}</span>
    </div>

    <ng-template #selectPayment>
      <span class="payment_title">{{ 'Виберіть платіжну систему:' | translate }}</span>

      <ng-select
        [items]="paymentSystemsByClinic"
        [clearable]="false"
        bindLabel="name"
        bindValue="type"
        [placeholder]="'Виберіть платіжну систему:' | translate"
        (change)="selectCurrentPayment($event)"
        [(ngModel)]="currentPaymentSystem.type"
      >
      </ng-select>
    </ng-template>

    <img
      class="payment-img"
      [src]="currentPaymentSystem.logo"
      [alt]="currentPaymentSystem.name"
    >

    <div class="payment_content">
      <app-payment-safaricom
        *ngIf="currentPaymentSystem.type === paymentSystemsEnum.Safaricom"
        [paymentSystem]="currentPaymentSystem"
        (sendData)="sendPayment($event)"
      >
      </app-payment-safaricom>
    </div>
  </div>
</div>
