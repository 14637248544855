import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'translate',
  pure: false
})
export class TranslatePipe implements PipeTransform {
  constructor(private translate: TranslateService) {
  }

  transform(key: any): any {
    const lang = this.translate.defaultLang;
    return this.translate.translations[lang] ? (this.translate.translations[lang][key] || key) : key;
  }
}
