<button
  class="menu-button"
  mat-icon-button
  #menuTrigger="matMenuTrigger"
  (menuOpened)="onMenuOpened()"
  (menuClosed)="onMenuClosed()"
  [matMenuTriggerFor]="menu"
>
  <span
    class="notifications_icon"
  >
    <img src="assets/images/notification_icon.svg">

    <span class="badge" *ngIf="total">
      {{ total > 99 ? '99+' : total }}
    </span>
  </span>
</button>

<mat-menu #menu="matMenu" backdropClass="notifications-menu-backdrop">
  <div class="notifications-menu" (click)="$event.stopPropagation()">
    <ng-container *ngIf="notifications?.length; else emptyResult">
      <div class="menu-header">
        <p
          class="notifications-counter">{{ total }} {{ total < 5 ? ('Сповищення' | translate) : ('Сповіщень' | translate) }}</p>

        <a (click)="removeAll($event)" class="notifications-remove-all" href="#">
          {{ 'Очистити все' | translate }}
        </a>
      </div>

      <div class="menu-body">
        <app-notification
          *ngFor="let notification of notifications"
          [notification]="notification"
          (action)="menuTrigger.closeMenu(); clicked.emit()"
        ></app-notification>
      </div>
    </ng-container>

    <ng-template #emptyResult>
      <div class="empty-result">
        {{ 'У Вас немає сповіщень на даний момент.' | translate }}
      </div>
    </ng-template>
  </div>
</mat-menu>
