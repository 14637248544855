export function buildErrorMessage(error): string {
  let errorMessage = 'Невідома помилка, спробуйте пізніше';

  if (error.customClientError) {
    return error.customClientErrorMessage;
  }

  switch (error.status) {
    case 401:
      errorMessage = 'Невірний Email або пароль';
      break;
    case 500:
      errorMessage = 'Помилка на сервері, спробуйте пізніше';
      break;
  }

  switch (error.error.code) {
    case 4020:
    case 4024:
    case 4027:
    case 4028:
    case 4030:
    case 7005:
    case 7009:
    case 10001:
    case 11006:
    case 11012:
    case 11013:
    case 11014:
    case 11021:
    case 11029:
    case 12001:
    case 20001:
    case 26000:
    case 29005:
    case 40000:
      errorMessage = error.error.errors;
      break;
    case 34002:
      errorMessage = error.error.errors.message;
      break;
    case 1000:
      errorMessage = Object.values(error.error.errors)[0][0];
  }

  return errorMessage;
}
