<mat-sidenav-container>
  <mat-sidenav-content class="position-relative">
    <app-header
      *ngIf="windowSizeConfig.isNoteBookDesktop || !hideElementsByRoute"
      id="main-header"
      [showNav]="hideElementsByRoute"
      (toggleSidenav)="sidenav.toggle()"
    ></app-header>

    <div class="wrapper-layout">
      <div
        class="app-layout"
        [class.mobile-chat]="!windowSizeConfig.isNoteBookDesktop && hideElementsByRoute"
      >
        <div class="app-layout-main">
          <app-nearest-consultation
            *ngIf="!hideElementsByRoute && (showNearestConsultation$ | async)"
          ></app-nearest-consultation>

          <router-outlet></router-outlet>
        </div>
      </div>

      <div class="wrapper_img" *ngIf="isResultPage">
        <img
          [src]="
          windowSizeConfig.isTablet
            ? ('consultation_end.svg' | imagePath)
            : ('heart.svg' | imagePath)"
          alt="end"
        >
      </div>
    </div>

    <app-footer *ngIf="windowSizeConfig.isSmallDesktop && !hideElementsByRoute"></app-footer>
  </mat-sidenav-content>

  <mat-sidenav #sidenav [mode]="'over'">
    <app-sidenav
      *ngIf="!windowSizeConfig.isNoteBookDesktop"
      (toggleSidenav)="sidenav.toggle()"
      (closeSidenav)="sidenav.close()"
    ></app-sidenav>
  </mat-sidenav>
</mat-sidenav-container>

<app-new-notification-listener></app-new-notification-listener>
