import {NgModule} from '@angular/core';
import {ModalNewUserComponent} from './modal-new-user.component';
import {ModalHeaderModule} from '../modal-header/modal-header.module';
import {SharedModule} from '../../../shared/shared.module';
import {ValidatorMessageModule} from '@shared-modules/validator-message/validator-message.module';
import {TranslateModule} from '@core/pipes/translate/translate.module';

@NgModule({
  declarations: [
    ModalNewUserComponent
  ],
  imports: [
    ModalHeaderModule,
    SharedModule,
    ValidatorMessageModule,
    TranslateModule
  ]
})
export class ModalNewUserModule {
}
