import {UserIdTypesEnum, UserTypesEnum} from '../enums/user-types.enum';

export function getUserTypeByTypeId(type: UserIdTypesEnum): UserTypesEnum {
  switch (type) {
    case UserIdTypesEnum.Doctor:
      return UserTypesEnum.Doctor;

    case UserIdTypesEnum.Patient:
      return UserTypesEnum.Patient;

    case UserIdTypesEnum.Admin:
      return UserTypesEnum.Admin;

    case UserIdTypesEnum.ClinicAdmin:
      return UserTypesEnum.ClinicAdmin;
  }
}
