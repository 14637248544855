import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import {Observable} from 'rxjs';

import {ProfileService} from '@profile/profile.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(private profileService: ProfileService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.profileService.isAuthenticated()) {
      this.router.navigate(['/doctors']);
      return false;
    }

    return true;
  }
}
