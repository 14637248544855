import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ModalManageConsultationComponent} from './modal-manage-consultation.component';
import {ModalHeaderModule} from '@shared-modules/modals/modal-header/modal-header.module';
import {SafeImageModule} from '@shared-modules/safe-image/safe-image.module';
import {TranslateModule} from '@core/pipes/translate/translate.module';
import {AddressByIdModule} from '@core/pipes/address-by-id/address-by-id.module';


@NgModule({
  declarations: [
    ModalManageConsultationComponent
  ],
  imports: [
    CommonModule,
    ModalHeaderModule,
    SafeImageModule,
    TranslateModule,
    AddressByIdModule
  ]
})
export class ModalManageConsultationModule {
}
