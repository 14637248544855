<ng-container *ngIf="field.invalid && field.touched && validatorMessages().length">
  <div class="error-message" *ngFor="let errMsg of validatorMessages()">
    <span *ngIf="typeOf(errMsg) === 'string'; else errorArray">
    {{ errMsg | translate }}
    </span>
    <ng-template #errorArray>
      <span *ngFor="let word of errMsg">{{ word | translate }} </span>
    </ng-template>
  </div>
</ng-container>
