import {
  ChatMessageTypesEnum
} from '../../../shared-modules/chat-messages/enums/chat-message-types.enum';

export function getMessageType(message): ChatMessageTypesEnum {
  if (message.file_id) {
    return ChatMessageTypesEnum.File;
  }

  if (message?.consultationsHistory) {
    return ChatMessageTypesEnum.ConsultationsHistory;
  }

  if (message?.message) {
    return ChatMessageTypesEnum.Text;
  }
}
