import {Injectable} from '@angular/core';
import {BehaviorSubject, fromEvent, Observable} from 'rxjs';
import {debounceTime, map, tap} from 'rxjs/operators';

import {WindowSizeConfigInterface} from '../interfaces/window-size-config.interface';
import {prepareWindowSizeConfig} from '@core/utils/prepare-window-size-config';

@Injectable({
  providedIn: 'root'
})
export class WindowSizeService {
  windowSizeConfig$: BehaviorSubject<WindowSizeConfigInterface>
    = new BehaviorSubject<WindowSizeConfigInterface>(prepareWindowSizeConfig(window.innerWidth));

  handleWindowSizeConfig(): Observable<WindowSizeConfigInterface> {
    return fromEvent(window, 'resize')
      .pipe(
        debounceTime(400),
        map((event: any) => prepareWindowSizeConfig(event.target.innerWidth)),
        tap((windowSize: WindowSizeConfigInterface) => this.windowSizeConfig$.next(windowSize))
      );
  }
}
