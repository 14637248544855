import {Component, OnInit} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {MatDialog} from '@angular/material/dialog';
import {finalize, mergeMap} from 'rxjs/operators';

import {Consultation} from '@consultations/interfaces/consultation.interface';
import {
  ConsultationsListFilter
} from '@consultations/interfaces/consultation-list-filter.interface';
import {ConsultationStatusesEnum} from '@consultations/enums/consultation-statuses.enum';
import {ProfileService} from '@profile/profile.service';
import {ConsultationsService} from '@consultations/consultations.service';
import {FilePreviewInterface} from '@entry-components/file-preview/file-preview.interface';
import {FileTypesEnum} from '@core/enums/file-types.enum';
import {FilePreviewComponent} from '@entry-components/file-preview/file-preview.component';


@UntilDestroy()
@Component({
  selector: 'app-recipes',
  templateUrl: './recipes.component.html',
  styleUrls: ['./recipes.component.scss']
})
export class RecipesComponent implements OnInit {
  lang$ = this.profileService.language$;

  displayedColumns: string[] = ['doctor', 'diagnosis', 'recommendations', 'date', 'medication'];
  consultations: Consultation[] = [];
  isLoading: boolean;

  filter: ConsultationsListFilter = {
    relation_pdf: 1,
    relation_doctor: 1,
    with_recommendation: 1,
    with_relation_specializations: 1,
    page: 1,
    perPage: 999,
    direction: 'DESC',
    field: 'created_at',
    statuses: [ConsultationStatusesEnum.Finished, ConsultationStatusesEnum.FinishedByTime]
  }

  constructor(
    private dialog: MatDialog,
    private profileService: ProfileService,
    private consultationsService: ConsultationsService
  ) {
  }

  ngOnInit(): void {
    this.getConsultations();
  }

  previewFile(name: string, storage: string): void {
    const data: FilePreviewInterface = {
      type: FileTypesEnum.PDF,
      fileName: name,
      storage: storage,
      title: storage.includes('direction') ? 'Направлення' : 'Лікування'
    };

    this.dialog.open(FilePreviewComponent, {
      data,
      panelClass: ['primary-modal', 'modal-xl'],
      autoFocus: false
    });
  }

  private getConsultations(): void {
    this.isLoading = true;

    this.profileService.profile$
      .pipe(
        mergeMap(profile => {
          this.filter.patient_id = profile.id;
          return this.consultationsService.getConsultationsList(this.filter);
        }),
        untilDestroyed(this)
      )
      .subscribe(res => {
        this.consultations = res.data;
        this.isLoading = false;
      });
  }
}
