<div class="chat-error text-center">
  <p class="message">
    {{ 'На жаль, у Вас виникли проблеми з Інтернетом при підключенні до консультації.' | translate }}
  </p>

  <p class="message">
    {{ 'Будь ласка, перевірте інтернет-з\'єднання і спробуйте ще раз приєднатися.' | translate }}
  </p>

  <div class="btn-to-home">
    <button
      [routerLink]="['/chat', chatId]"
      class="btn btn-secondary"
      [disabled]="!isActiveReconnectBtn"
    >
      {{ 'Спробувати ще раз' | translate }}
    </button>
  </div>
</div>
