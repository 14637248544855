import {Component, Input} from '@angular/core';
import {Observable} from 'rxjs';

import {ProfileService} from '@profile/profile.service';
import {Patient} from '@core/interfaces/patient.interface';

@Component({
  selector: 'app-profile-header',
  templateUrl: './profile-header.component.html',
  styleUrls: ['./profile-header.component.scss']
})
export class ProfileHeaderComponent {
  @Input() isChatPage: boolean;

  profile$: Observable<Patient> = this.profileService.profile$;

  constructor(private profileService: ProfileService) {
  }
}
