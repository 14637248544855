export enum UserTypesEnum {
  Admin = 'admin',
  Patient = 'patient',
  Doctor = 'doctor',
  ClinicAdmin = 'admin_clinic'
}

export enum UserIdTypesEnum {
  Admin = 0,
  Doctor = 1,
  Patient = 2,
  ClinicAdmin = 3
}
