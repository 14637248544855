<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="title">{{ 'Як до Вас звертатися?' | translate }}</div>

  <mat-form-field>
    <input matInput [placeholder]="'Ім\'я' | translate" formControlName="name"/>

    <mat-error>
      <app-validator-message [key]="'name'" [field]="form.get('name')"></app-validator-message>
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <input matInput [placeholder]="'Прізвище' | translate" formControlName="surname"/>

    <mat-error>
      <app-validator-message
        [key]="'surname'"
        [field]="form.get('surname')"
      >
      </app-validator-message>
    </mat-error>
  </mat-form-field>

  <button class="btn btn-primary" type="submit" [disabled]="form.invalid || form.disabled">
    {{ btnText | translate }}
  </button>
</form>
