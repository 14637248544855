import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';

import {environment} from '../../../environments/environment';
import {getObjectUrlParams, getStringUrlParams} from '@core/utils/filter-converter';
import {
  ConsultationReviewListFilter
} from '@doctors/interfaces/consultation-review-list-filter.interface';
import {ConsultationsListFilter} from './interfaces/consultation-list-filter.interface';
import {ConsultationFilter} from './interfaces/consultation-filter.interface';
import {JsonaService} from '@core/services/jsona.service';
import {DeserializedJsonInterface} from '@core/interfaces/jsona.interface';
import {Consultation} from '@consultations/interfaces/consultation.interface';
import {ConsultationReviewInterface} from '@doctors/interfaces/consultation-review.interface';
import {ConsultationStatusesEnum} from '@consultations/enums/consultation-statuses.enum';
import {ResolvePaymentService} from '@shared-modules/payment/services/resolve-payment.service';

@Injectable({
  providedIn: 'root'
})
export class ConsultationsService {
  private apiUrl = `${environment.apiUrl}/consultations`;
  private apiUrlV2 = `${environment.apiUrlV2}/consultations`;

  constructor(
    private http: HttpClient,
    private jsonaService: JsonaService,
    private router: Router,
    private paymentService: ResolvePaymentService
  ) {
  }

  // V2
  getConsultationsList(filter: ConsultationsListFilter): Observable<DeserializedJsonInterface<Consultation[]>> {
    const params = getStringUrlParams(filter);

    return this.http
      .get(`${this.apiUrlV2}?${params}`)
      .pipe(
        map((result) => this.jsonaService.deserialize(result))
      );
  }

  getConsultationById(
    id: number,
    filter: ConsultationFilter
  ): Observable<DeserializedJsonInterface<Consultation>> {
    const params = getStringUrlParams(filter);

    return this.http
      .get(`${this.apiUrlV2}/${id}?${params}`)
      .pipe(
        map(result => this.jsonaService.deserialize(result))
      );
  }

  //V1
  cancelConsultation(consultationId: number): Promise<void> {
    return firstValueFrom(
      this.http.delete<void>(`${this.apiUrl}/${consultationId}`)
    );
  }

  changeConsultationStatus(id: number, statusId: number): Observable<void> {
    return this.http.get<void>(`${this.apiUrl}/change-status/${id}/${statusId}`);
  }

  shareConsultationsHistory(params: any): Observable<any> {
    return this.http.post(`${this.apiUrlV2}/share`, params);
  }

  getReviewList(
    filter: ConsultationReviewListFilter
  ): Observable<DeserializedJsonInterface<ConsultationReviewInterface[]>> {
    const params = getObjectUrlParams(filter);

    return this.http.get(
      `${this.apiUrlV2}/review/list`,
      {params}
    )
      .pipe(
        map(result => this.jsonaService.deserialize(result))
      );
  }

  onStartConsultation(consultation: Consultation): void {
    if (consultation.status === ConsultationStatusesEnum.Started) {
      this.goToOnlineChat(consultation.id);

      return;
    }

    this.paymentService.resolvePayment(
      {price: consultation.price},
      () => this.goToOnlineChat(consultation.id)
    );
  }

  private goToOnlineChat(id: number): void {
    this.router.navigate(['/chat', id]);
  }
}
