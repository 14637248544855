<div class="safaricom">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div class="phone phone_payment form_field">
      <ngx-intl-tel-input
        [cssClass]="'phone_field'"
        [enableAutoCountrySelect]="true"
        [enablePlaceholder]="false"
        [searchCountryFlag]="true"
        [searchCountryField]="[
              searchCountryField.Iso2,
              searchCountryField.Name,
              searchCountryField.DialCode
            ]"
        [selectFirstCountry]="false"
        [searchCountryPlaceholder]="'Виберіть країну' | translate"
        [selectedCountryISO]="currentCountry"
        [separateDialCode]="true"
        [phoneValidation]="false"
        [maxLength]="phoneLength"
        [numberFormat]="phoneNumberFormat"
        formControlName="phone"
        (countryChange)="selectCountry()"
      ></ngx-intl-tel-input>

      <div class="phone_placeholder">
        <span>{{ 'Телефон' | translate }} *</span>
      </div>

      <mat-error class="custom_error">
        <app-validator-message
          [key]="'phone'"
          [field]="form.get('phone')"
        ></app-validator-message>
      </mat-error>
    </div>

    <mat-form-field appearance="outline" class="form_field">
      <mat-label>{{ 'Сума' | translate }}:</mat-label>
      <input
        matInput
        [placeholder]="'Сума' | translate"
        formControlName="amount"
        appNumbersOnly
        [maxLength]="4"
        [minLength]="1"
        required
      />
      <mat-error>
        <app-validator-message [field]="form.get('amount')"></app-validator-message>
      </mat-error>
    </mat-form-field>

    <div class="save">
      <button class="btn btn-primary" type="submit" [disabled]="form.invalid || form.disabled">
        {{ 'Поповнити' | translate }}
      </button>
    </div>

    <p class="privacy">
      {{ 'Я підтверджую, що ознайомився(лась) з' | translate }}
      <a [href]="termsOfUse" target="_blank">
        {{ 'політикою обробки персональних даних' | translate }}
      </a>
      {{ 'i' | translate }}
      <a [href]="termsOfUse" target="_blank">
        {{ 'даю згоду на обробку персональних даних' | translate }}
      </a>
      , {{ 'ознайомлений(а) з ' | translate }}
      <a [href]="termsOfUse" target="_blank">{{ 'користувальницькою угодою' | translate }}</a>
      {{ ' та згоден(на) з умовами надання послуг на сайті' | translate }}
    </p>
  </form>
</div>
