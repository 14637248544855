import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NearestConsultationService {
  showNearestConsultation$ = new BehaviorSubject<boolean | null>(null);
  rescheduleConsultation$ = new BehaviorSubject<number | null>(null);
  updateNearestConsultation$ = new BehaviorSubject<boolean | null>(null);

  setLocalSetting(value): void {
    this.showNearestConsultation$.next(value);
    localStorage.setItem('showNearest', value);
  }

  getLocalSettings(): string | null {
    return localStorage.getItem('showNearest');
  }
}
