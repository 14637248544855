import {Injectable} from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ProfileService} from '@profile/profile.service';

@Injectable({
  providedIn: 'root'
})
export class AppGuard  {
  constructor(
    private router: Router,
    private profileService: ProfileService
  ) {
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.profileService.isAuthenticated()) {
      if (!this.profileService.profile$.value) {
        return this.profileService.loadProfile().pipe(map(() => true));
      }

      return true;
    }

    this.router.navigate(['/auth/login']);
    return false;
  }
}
