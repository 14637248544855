<div class="modal_content">
  <div class="modal_image">
    <img [src]="image | imagePath" [alt]="image">
  </div>

  <div class="modal-wrap modal-wrap_info">
    <app-modal-header [title]="data.title"></app-modal-header>

    <div class="dialog-content">
      <p class="text-center" *ngIf="!(data.message | isArray); else messageArray">
        {{ data.message | translate }}
      </p>

      <ng-template #messageArray>
        <p class="text-center">
          <span *ngFor="let word of data.message">
           {{ word | translate }}
          </span>
        </p>
      </ng-template>

      <div class="payment_info" *ngIf="(hasPayment$ | async) && data.showPaymentInfo">
        <p class="text-center">
          {{ 'Перед початком консультації, переконайтесь що на вашому рахунку достатньо баллів' | translate }}
        </p>
      </div>

      <mat-dialog-actions>
        <button class="btn btn-primary" [mat-dialog-close]="false">
          {{ (data.btnText | translate) || ('Закрити' | translate) }}
        </button>
      </mat-dialog-actions>
    </div>
  </div>
</div>
