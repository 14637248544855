import {Component, EventEmitter, OnDestroy, Output} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {WindowSizeConfigInterface} from '@core/interfaces/window-size-config.interface';
import {WindowSizeService} from '@core/services/window-size.service';
import {ProfileService} from '@profile/profile.service';
import {ClinicIdsEnum} from '@core/enums/clinic-ids.enum';
import {PaymentService} from '@shared-modules/payment/services/payment.service';


@UntilDestroy()

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnDestroy {
  @Output() toggleSidenav: EventEmitter<null> = new EventEmitter();
  @Output() closeSidenav: EventEmitter<null> = new EventEmitter();

  windowSizeConfig: WindowSizeConfigInterface;
  activeClinic$ = this.profileService.activeClinic$;
  hasPayment$ = this.paymentService.hasPayment$;
  clinicIds = ClinicIdsEnum;
  termsOfUse = (window as any).environments.termsOfUseLink;

  constructor(
    private windowSizeService: WindowSizeService,
    private profileService: ProfileService,
    private paymentService: PaymentService
  ) {
    this.handleWindowSizeConfig();
  }

  ngOnDestroy(): void {
    this.closeSidenav.emit();
  }

  private handleWindowSizeConfig(): void {
    this.windowSizeService.windowSizeConfig$
      .pipe(untilDestroyed(this))
      .subscribe((windowSizeConfig: WindowSizeConfigInterface) => {
        this.windowSizeConfig = windowSizeConfig;
      });
  }
}
