import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetworkStateService {
  // for Centrifugo
  networkConnectionState$: BehaviorSubject<string | null> = new BehaviorSubject(null);

  checkNetworkState(): void {
    window.ononline = (e) => {
      this.networkConnectionState$.next(e.type);
    };

    window.onoffline = (e) => {
      this.networkConnectionState$.next(e.type);
    }
  }
}
