import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Params} from '@angular/router';

import {InternalUrlParams} from '@auth/interfaces/internal-url-params.interface';

@Injectable({
  providedIn: 'root'
})
export class InternalUrlService {
  stringUrlParams$ = new BehaviorSubject(null);

  setInternalUrlByParams(): void {
    const stringUrlParams = window.location.search.substring(1);

    if (stringUrlParams.includes('internalurl')) {
      this.stringUrlParams$.next(stringUrlParams);
    }
  }

  buildInternalUrlParams(params: InternalUrlParams | Params, isRegistration = false): string {
    if (!params.internalurl) {
      return;
    }

    let internalUrl = isRegistration ? `?internalurl=${params.internalurl}` : `/${params.internalurl}`;

    if (params.analyzes) {
      internalUrl += `${isRegistration ? '&' : '?'}analyzes=${params.analyzes}`;
    }

    if (params.specializations) {
      internalUrl += `${isRegistration ? '&' : '?'}specializations=${params.specializations}`;
    }

    if (params.paymentStatus) {
      internalUrl += `${isRegistration ? '&' : '?'}paymentStatus=${params.paymentStatus}`;
    }

    return decodeURIComponent(internalUrl);
  }
}
