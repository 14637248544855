import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {mergeMap} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {ProfilePreNameService} from '../profile-pre-name/profile-pre-name.service';
import {ProfileService} from '@profile/profile.service';

@Component({
  selector: 'app-profile-pre-name',
  templateUrl: './profile-pre-name.component.html',
  styleUrls: ['./profile-pre-name.component.scss']
})

@UntilDestroy()

export class ProfilePreNameComponent {
  @Input() btnText: string;

  @Output() requestResolved: EventEmitter<boolean> = new EventEmitter<boolean>();

  form: FormGroup = this.fb.group({
    name: ['', [Validators.required, Validators.maxLength(50)]],
    surname: ['', [Validators.required, Validators.maxLength(50)]]
  });

  constructor(
    private fb: FormBuilder,
    private profilePreNameService: ProfilePreNameService,
    private profileService: ProfileService,
  ) {
  }

  submit(): void {
    if (this.form.invalid) {
      return this.form.markAllAsTouched();
    }

    this.form.disable();

    this.setProfilePreName();
  }

  private setProfilePreName(): void {
    this.profilePreNameService
      .setProfilePreName({name: this.form.getRawValue().name, surname: this.form.getRawValue().surname})
      .pipe(
        mergeMap(() => this.profileService.loadProfile()),
        untilDestroyed(this)
      )
      .subscribe(
        () => this.requestResolved.emit(true),
        () => this.requestResolved.emit(false)
      );
  }
}
