import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModalSearchSpecialistComponent} from './modal-search-specialist.component';
import {SharedModule} from '../../../shared/shared.module';
import {ValidatorMessageModule} from '@shared-modules/validator-message/validator-message.module';
import {ImagePathModule} from '@core/pipes/image-path/image-path.module';
import {TranslateModule} from '@core/pipes/translate/translate.module';


@NgModule({
  declarations: [
    ModalSearchSpecialistComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ValidatorMessageModule,
    ImagePathModule,
    TranslateModule
  ]
})
export class ModalSearchSpecialistModule {
}
