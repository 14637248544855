import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appNumbersOnly]'
})
export class NumberOnlyDirective {
  @Input() withPlus: boolean;

  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const reg = this.withPlus ? /[^0-9+]*/g : /[^0-9]*/g;

    const initalValue = this._el.nativeElement.value;
    this._el.nativeElement.value = initalValue.replace(reg, '');
    if ( initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
