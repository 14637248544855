import {Component, DestroyRef, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

import {ProfileService} from '@profile/profile.service';
import {localToken} from './app.constants';
import {InternalUrlService} from '@core/services/internal-url.service';
import {NetworkStateService} from '@shared-modules/network-state/network-state.service';
import {WindowSizeService} from '@core/services/window-size.service';
import {Crypto} from '@core/classes/crypto.class';
import {PaymentService} from '@shared-modules/payment/services/payment.service';
import {CentrifugoSocketService} from '@core/services/centrifugo-socket.service';
import {LanguagesEnum} from '@shared-modules/layouts/app-layout/enums/languages.enum';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [
    {provide: Window, useValue: window}
  ]
})
export class AppComponent implements OnInit {
  crypto: Crypto = new Crypto();
  favIcon: HTMLLinkElement = document.querySelector('#favicon');

  constructor(
    private profileService: ProfileService,
    private centrifugoSocketService: CentrifugoSocketService,
    private networkStateService: NetworkStateService,
    private internalUrlService: InternalUrlService,
    private windowSizeService: WindowSizeService,
    private paymentService: PaymentService,
    private titleService: Title,
    private translateService: TranslateService,
    private window: Window,
    private destroyRef: DestroyRef
  ) {
  }

  ngOnInit(): void {
    this.internalUrlService.setInternalUrlByParams();

    this.storageListener();
    this.setLanguageAndPayment();

    this.networkStateService.checkNetworkState();

    if (this.profileService.isAuthenticated()) {
      this.handleWindowSizeConfig();
    }

    this.setFaviconAndTitle();
  }

  private storageListener(): void {
    window.addEventListener('storage', (event) => {
      if (event.key === this.crypto.encrypt(localToken) && !event.newValue) {
        this.centrifugoSocketService.socketDisconnect();
        this.profileService.logout();
      }
    });
  }

  private handleWindowSizeConfig(): void {
    this.windowSizeService.handleWindowSizeConfig()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  private setFaviconAndTitle(): void {
    this.favIcon.href = `/assets/clinics/${(window as any).environments.directory}/favicon.ico`;
    this.titleService.setTitle((window as any).environments.clinicName);
  }

  private setLanguageAndPayment(): void {
    const browserLang = this.translateService.getBrowserLang();
    let currentLang = LanguagesEnum.UA as string;

    if (Object.values(LanguagesEnum).includes(browserLang as LanguagesEnum)) {
      currentLang = browserLang;
    }

    this.profileService.language$.next(currentLang);
    this.translateService.setDefaultLang(currentLang);
    this.paymentService.hasPayment$.next((this.window as any).environments.payment);

    if ((this.window as any).environments.payment) {
      this.paymentService.paymentSystems$.next((this.window as any).environments.paymentSystem);
    }
  }
}
