import * as moment from 'moment';

import {NotificationTypesEnum} from '../enums/notification-types.enum';
import {
  NotificationInitiator,
  Notification
} from '../../notifications/interfaces/notification.interface';

const prepareNotificationByTypeHandlers = {
  [NotificationTypesEnum.PatientRecommendationDone]: prepareRecommendationDone,
  [NotificationTypesEnum.NewDiscussionMessage]: prepareNewDiscussionMessage,
  [NotificationTypesEnum.AddMoney]: prepareAddMoney,
  [NotificationTypesEnum.PaymentFail]: preparePaymentFail,
  [NotificationTypesEnum.AddMedicationSchedule]: prepareMedication,
  [NotificationTypesEnum.TimeToMedicationPoint]: prepareTimeToMedicationPoint,
  [NotificationTypesEnum.CancelConsultationByDoctor]: prepareCancelConsultation,
};

function defaultNotificationData(item, users) {
  const id = item.attributes.data.initiator_id;
  const type = item.attributes.data.initiator_type;
  const initiatorCandidate = `${id}_${type}`;
  const fullName = item.attributes.data.fullname || `${users[initiatorCandidate].data.attributes.surname || ''}
    ${users[initiatorCandidate].data.attributes.name || ''}
    ${users[initiatorCandidate].data.attributes.middle_name || ''}`;

  const initiator: NotificationInitiator = {
    id,
    type,
    fullName,
    avatar: item.attributes?.data?.avatar || users[initiatorCandidate].data?.attributes?.avatar,
    avatarStorage: item.attributes?.data?.storage || users[initiatorCandidate].data?.attributes?.avatar_storage
  };

  return {
    initiator,
    id: item.id,
    createdAt: item.attributes.created_at,
    userId: item.attributes.user_id,
    userType: item.attributes.user_type,
    status: item.attributes.status,
    sendTo: item.attributes.data.send_to,
    type: item.attributes.data.type,
    time: moment.unix(item.attributes.data.time)
  };
}

function prepareMedication(itemData, lang) {
  return {
    appointmentId: itemData.appointment_id,
    iconName: '',
    message: 'Вам прийшло призначення від лікаря. Перевірте детальну інформацію в',
    action: {
      text: 'медичному трекері',
      target: `/tracker`
    }
  };
}

function prepareCancelConsultation() {
  return {
    iconName: 'icon-notification-cancel-consultation',
    message: 'відмінив',
    action: {
      text: 'консультацію',
      target: `/consultations/canceled`,
    },
  };
}

function prepareTimeToMedicationPoint(itemData, lang) {
  return {
    appointmentId: itemData.appointment_id,
    iconName: '',
    message: 'Прийшов час виконати/прийняти',
    action: {
      text: 'процедуру/ліки',
      target: `/tracker`
    }
  };
}

function prepareRecommendationDone(itemData, lang) {
  return {
    iconName: 'icon-notification-recommendation-status',
    anamnesisId: itemData.anamnesis_id,
    consultationId: itemData.consultation_id,
    doctorId: itemData.doctor_id,
    message: 'залишив(ла) рекомендацiю за',
    action: {
      text: moment.unix(itemData.time).locale(lang).format('DD MMMM'),
      target: '/consultations/past',
    },
  };
}

function prepareNewDiscussionMessage(itemData, lang) {
  return {
    iconName: 'icon-message',
    message: 'відправив вам',
    action: {
      text: 'повiдомлення',
      target: `/dialogues/${itemData.initiator_id}/${itemData.discussion_id}`,
    },
  };
}

function prepareAddMoney(itemData, lang) {
  return {
    iconName: 'icon-notification-success-payout',
    message: ['Ваш баланс успiшно поповнений на', `${itemData.amount}`, 'балів']
  };
}

function preparePaymentFail(itemData) {
  return {
    iconName: 'icon-notification-success-payout',
    message: 'Спроба поповнення рахунку була невдалою'
  };
}

export function prepareNotification(item, users, lang): Notification {
  return {
    ...defaultNotificationData(item, users),
    ...prepareNotificationByTypeHandlers[item?.attributes?.data?.type](item?.attributes?.data, lang)
  };
}

export function prepareNewNotification(item, lang): Notification {
  const initiator: NotificationInitiator = {
    id: item.initiator_id,
    type: item.initiator_type,
    avatar: item.avatar,
    avatarStorage: item.storage,
    fullName: item.fullname
  };

  return {
    initiator,
    time: moment.unix(item.time).format('DD.MM HH:mm'),
    type: item.type,
    userType: item.user_type,
    sendTo: item.send_to,
    ...prepareNotificationByTypeHandlers[item.type](item, lang)
  };
}
