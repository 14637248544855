import {Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';

import {hasOwnProperty} from '@core/utils/has-own-property';
import {makeWordEnding} from '@core/utils/make-word-ending';

@Component({
  selector: 'app-validator-message',
  templateUrl: './validator-message.component.html'
})
export class ValidatorMessageComponent {
  @Input() field: FormControl;

  @Input() key: string;

  validatorMessages(): string[] {
    const field = this.field;

    if (!field || !field.errors) {
      return [];
    }

    const errors = [];
    const config = {
      matDatepickerParse: 'Обовʼязкове поле',
      required: 'Обовʼязкове поле',
      requiredTrue: 'Значення має бути більше нуля',
      email: 'Невірний email',
      pattern: 'Невірний',
      passwordNotEqual: 'Паролі не сходяться',
      oldPasswordAreEqual: 'Новий пароль сходиться зі старим'
    };

    if (hasOwnProperty(field.errors, 'custom')) {
      config['custom'] =
        typeof field.errors.custom === 'string' && field.errors.custom.length
          ? field.errors.custom
          : 'Не відповідає формату';
    }

    if (hasOwnProperty(field.errors, 'minlength')) {
      const charEnding = makeWordEnding(field.errors.minlength.requiredLength);
      config['minlength'] = ['Мінімум', `${field.errors.minlength.requiredLength}`, `символ${charEnding}`];
    }

    if (hasOwnProperty(field.errors, 'maxlength')) {
      const charEnding = makeWordEnding(field.errors.maxlength.requiredLength);
      config['maxlength'] = ['Максимум', `${field.errors.maxlength.requiredLength}`, `символ${charEnding}`];
    }

    if (hasOwnProperty(field.errors, 'min')) {
      config['min'] = ['Мінімальне значення', `${field.errors.min.min}`];
    }

    if (hasOwnProperty(field.errors, 'max')) {
      config['max'] = ['Максимальне значення', `${field.errors.max.max}`];
    }

    if (hasOwnProperty(field.errors, 'whitespace')) {
      config['whitespace'] = [`${field.errors.whitespace}`, 'не може бути пустим'];
    }

    Object.keys(field.errors).forEach((error: string) => {
      if (this.key === 'email') {
        errors.push(config[this.key]);
        return;
      }

      if (error === 'pattern') {
        const text = this.key === 'phone' ? 'номер телефону' : this.key;
        errors.push([`${config[error]}, ${text}`]);
        return;
      }

      errors.push(config[error]);
    });

    return errors;
  }

  typeOf(value) {
    return typeof value;
  }
}
