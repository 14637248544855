import {LanguagesEnum} from "../enums/languages.enum";

export const LanguagesConst = [
  {
    language: LanguagesEnum.UA,
    fullName: 'Українська',
    shortName: 'УКР',
    image: '/assets/images/uk.png'
  },
  {
    language: LanguagesEnum.EN,
    fullName: 'English',
    shortName: 'EN',
    image: '/assets/images/en.png'
  },
  {
    language: LanguagesEnum.RU,
    fullName: 'Русский',
    shortName: 'РУС',
    image: ''
  },
]

export const LocalesConst = {
  [LanguagesEnum.EN]: 'en_EN',
  [LanguagesEnum.UA]: 'uk_UA',
  [LanguagesEnum.RU]: 'ru_RU'
}
