<a
  *ngIf="profile$ | async as profile"
  [routerLink]="['/profile']"
  [class.not-user-select]="isChatPage"
  routerLinkActive="active"
  class="profile"
>
   <span class="profile_avatar">
    <img src="assets/images/avatar_icon.svg" alt="avatar">
  </span>
</a>
