<div class="chat-error text-center">
  <p class="message">
    {{ 'Вибачте, у лікаря виникли проблеми з інтернетом при підключенні до консультації.' | translate }}
  </p>

  <div class="btn-to-home">
    <button [routerLink]="['/chat', chatId]" class="btn btn-secondary">
      {{ 'Спробувати ще раз' | translate }}
    </button>
  </div>
</div>
