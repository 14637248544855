<div class="nearest" *ngIf="consultation">
  <div
    class="nearest_wrap"
    [class.offline]="consultation?.type_communication === typeCommunicationEnum.Offline"
  >
    <div class="close" (click)="close()">
      <mat-icon>close</mat-icon>
    </div>

    <div class="nearest_title" *ngIf="!hideTimer; else consultationStarted">
      {{ 'Консультація через:' | translate }}
    </div>

    <ng-template #consultationStarted>
      <div class="nearest_title">{{ 'Консультація почалась' | translate }}</div>
    </ng-template>

    <div class="nearest_time" *ngIf="!hideTimer">
      <div class="time_item day" *ngIf="startDays">
        <span>{{ startDays > 9 ? startDays : '0' + startDays }}</span>
        <span>{{ 'дн.' | translate }}</span>
      </div>
      <div class="time_item hour" *ngIf="startMinutes ?? 0">
        <span>{{ startHours > 9 ? startHours : '0' + startHours }}</span>
        <span>{{ 'год.' | translate }}</span>
      </div>
      <div class="time_item minute" *ngIf="startMinutes ?? 0">
        <span>{{ startMinutes > 9 ? startMinutes : '0' + startMinutes }}</span>
        <span>{{ 'хв.' | translate }}</span>
      </div>
    </div>

    <div class="nearest_action"
         *ngIf="consultation?.type_communication === typeCommunicationEnum.Online">
      <button
        class="btn btn-secondary"
        [class.omnycare]="(activeClinic$ | async).id === clinicIds.DentistOmnycare"
        (click)="startConsultation(consultation)">
        {{ 'Приєднатись' | translate }}
      </button>
    </div>
  </div>
</div>
