import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {CountryISO, PhoneNumberFormat, SearchCountryField} from 'ngx-intl-tel-input';

import {PaymentSystemInterface} from '@shared-modules/payment/interfaces/payment-system.interface';
import {ProfileService} from '@profile/profile.service';

@UntilDestroy()

@Component({
  selector: 'app-payment-safaricom',
  templateUrl: './payment-safaricom.component.html',
  styleUrls: ['./payment-safaricom.component.scss']
})
export class PaymentSafaricomComponent implements OnInit {
  @Input() paymentSystem: PaymentSystemInterface;
  @Output() sendData = new EventEmitter();

  form: FormGroup;

  searchCountryField = SearchCountryField;
  currentCountry;
  phoneNumberFormat = PhoneNumberFormat;
  phoneLength = 12;

  termsOfUse = (window as any).environments.termsOfUseLink;

  constructor(
    private fb: FormBuilder,
    private profileService: ProfileService
  ) {
  }

  ngOnInit(): void {
    this.initForm();
    this.setCountryAndPhone();
  }

  selectCountry(): void {
    this.form.get('phone').patchValue('');
  }

  submit() {
    if (this.form.invalid) {
      return this.form.markAllAsTouched();
    }

    const data = {
      amount: this.form.value.amount,
      code: this.form.value.phone.dialCode,
      phone: this.form.value.phone.number.trim().replace(/[^\d;]/g, '')
    };

    this.form.disable();
    this.sendData.emit({type: this.paymentSystem.type, data});
  }

  private initForm(): void {
    this.form = this.fb.group({
      phone: ['', Validators.required],
      amount: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(4)]]
    });
  }

  private setCountryAndPhone(): void {
    this.profileService.profile$
      .pipe(untilDestroyed(this))
      .subscribe(profile => {
        this.form.get('phone').patchValue(profile?.phone || '');

        this.currentCountry = profile?.phoneCountry
          ? profile?.phoneCountry
          : CountryISO[(window as any).environments.country];
      });
  }
}
