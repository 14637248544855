import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '../../../environments/environment';
import {getObjectUrlParams} from '../utils/filter-converter';
import {WalletInterface} from '@core/interfaces/wallet/wallet.interface';
import {GetWalletParamsInterface} from '@core/interfaces/wallet/wallet-params.interface';
import {UserIdTypesEnum} from '@core/enums/user-types.enum';
import {ProfileService} from '@profile/profile.service';
import {ApiWalletResponseInterface} from '@core/interfaces/wallet/wallet-response.interface';

@Injectable({
  providedIn: 'root'
})
export class WalletService {
  walletUpdated$: Subject<boolean> = new Subject<boolean>();

  private apiUrl = `${environment.apiUrl}/account/entity`;

  constructor(
    public dialog: MatDialog,
    private http: HttpClient,
    private profileService: ProfileService,
  ) {}

  getWallet(): Observable<WalletInterface> {
    const params = getObjectUrlParams({
      entity_id: this.profileService.profile$.value.id,
      entity_type: UserIdTypesEnum.Patient
    } as GetWalletParamsInterface);

    return this.http.get<ApiWalletResponseInterface>(this.apiUrl, {params})
      .pipe(
        map((result: ApiWalletResponseInterface) => {
          return {
            id: result.data.id,
            type: result.data.type,
            ...result.data.attributes
          };
        })
      );
  }
}
