import {Component} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-link-doctors',
  templateUrl: './link-doctors.component.html',
  styleUrls: ['./link-doctors.component.scss']
})
export class LinkDoctorsComponent {
  constructor(public router: Router) {
  }
}
