import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {ModalRefillInterface} from '../../../payment/interfaces/modal-refill.interface';
import {ModalInfoService} from '@core/services/modal-info.service';
import {ProfileService} from '@profile/profile.service';
import {PaymentService} from '@shared-modules/payment/services/payment.service';
import {PaymentSystemInterface} from '@shared-modules/payment/interfaces/payment-system.interface';
import {PaymentSystemsConst} from '@shared-modules/payment/constants/payment-systems.const';
import {PaymentSystemsEnum} from '@shared-modules/payment/enums/payment-systems.enum';

@UntilDestroy()

@Component({
  selector: 'app-modal-refill',
  templateUrl: './modal-refill.component.html',
  styleUrls: ['./modal-refill.component.scss'],
})
export class ModalRefillComponent implements OnInit {
  currentPaymentSystem: PaymentSystemInterface | null;
  paymentSystemsByClinic: PaymentSystemInterface[] = [];
  paymentSystems = PaymentSystemsConst;
  paymentSystemsEnum = PaymentSystemsEnum;
  hasSelectPayment: boolean;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalRefillInterface,
    public modal: MatDialogRef<ModalRefillComponent>,
    private modalInfoService: ModalInfoService,
    private profileService: ProfileService,
    private paymentService: PaymentService,
  ) {
  }

  ngOnInit(): void {
    this.getPaymentSystems();
  }

  selectCurrentPayment(event: PaymentSystemInterface): void {
    this.currentPaymentSystem = event;
  }

  sendPayment(event): void {
    this.paymentService.sendPayment(event)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => this.modal.close(true),
        (e) => {
          this.modal.close();
          this.modalInfoService.onError({
            customClientError: true,
            customClientErrorMessage: 'Щось пішло не так, спробуйте пізніше'
          });
        });
  }

  private getPaymentSystems(): void {
    this.paymentService.paymentSystems$
      .pipe(untilDestroyed(this))
      .subscribe((systems: string[]) => {
        this.paymentSystemsByClinic = systems.map(el => this.paymentSystems.find(item => item.type === el));
        this.currentPaymentSystem = this.paymentSystemsByClinic[0];
        this.hasSelectPayment = this.paymentSystemsByClinic.length > 1;
      });
  }
}
