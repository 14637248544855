import {ApiMessageInterface} from '../interfaces/message/message.interface';
import {
  ChatMessageInterface
} from '../../../shared-modules/chat-messages/interfaces/chat-messages.interface';
import {getUserTypeByTypeId} from '../../../core/utils/get-user-type-by-type-id';
import {
  ChatMessageTypesEnum
} from '../../../shared-modules/chat-messages/enums/chat-message-types.enum';
import {linkPattern} from '../../../core/constants/patterns.const';
import {buildTagLink} from '../../../core/utils/build-tag-link';
import {getMessageType} from '../utils/get-message-type';

export class PrepareMessageFromApi {
  private messageFromApi: ChatMessageInterface = this.prepareMessage();

  constructor(public data: ApiMessageInterface) {
  }

  get message(): ChatMessageInterface {
    return this.messageFromApi;
  }

  private prepareMessage(): ChatMessageInterface {
    const message: ChatMessageInterface = {
      message: this.data.attributes.message,
      user_id: this.data.attributes.discussion_id,
      created_at: this.data.attributes.created_at,
      role: getUserTypeByTypeId(this.data.attributes.user_type),
      type: getMessageType(this.data.attributes)
    };

    if (message.type === ChatMessageTypesEnum.File) {
      message.file = this.data.attributes.file.file;
      message.file_storage = this.data.attributes.file.file_storage;
      message.file_extension = this.data.attributes.file.file.split('.')[1];
    }

    if (message.type === ChatMessageTypesEnum.ConsultationsHistory) {
      message.consultationsHistory = this.data.attributes.consultationsHistory.ids;
    }

    if (message.type === ChatMessageTypesEnum.Text && linkPattern.test(message.message)) {
      message.message = buildTagLink(message.message);
    }

    return message;
  }
}
