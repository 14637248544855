import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  ModalManageByCommunicationTypeComponent
} from './modal-manage-by-communication-type.component';
import {SafeImageModule} from '../../safe-image/safe-image.module';
import {TranslateModule} from '@core/pipes/translate/translate.module';
import {SharedModule} from '../../../shared/shared.module';
import {AddressByIdModule} from '@core/pipes/address-by-id/address-by-id.module';


@NgModule({
  declarations: [
    ModalManageByCommunicationTypeComponent
  ],
  imports: [
    CommonModule,
    SafeImageModule,
    TranslateModule,
    SharedModule,
    AddressByIdModule
  ]
})
export class ModalManageByCommunicationTypeModule {
}
