import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '../../../environments/environment';
import {getObjectUrlParams} from '@core/utils/filter-converter';
import {ClinicsFilter} from '../interfaces/clinics-filter.interface';
import {ChangeActiveClinicInterface, Clinic} from '../interfaces/clinic.interface';
import {JsonaService} from '@core/services/jsona.service';
import {DeserializedJsonInterface} from '@core/interfaces/jsona.interface';

@Injectable()
export class ClinicsService {
  private apiUrlV2 = `${environment.apiUrl}/v2`;

  constructor(
    private http: HttpClient,
    private jsonaService: JsonaService
  ) {}


  getList(filter: ClinicsFilter): Observable<DeserializedJsonInterface<Clinic[]>> {
    const params = getObjectUrlParams(filter);

    return this.http.get(`${this.apiUrlV2}/clinic`, {params})
      .pipe(
        map(result => this.jsonaService.deserialize(result))
      );
  }

  changeActive(data: {clinic_id: number}): Observable<DeserializedJsonInterface<ChangeActiveClinicInterface>> {
    return this.http.post(`${this.apiUrlV2}/clinic-user/change-active`, data)
      .pipe(
        map(result => this.jsonaService.deserialize(result))
      );
  }

  byId(id: number): Observable<DeserializedJsonInterface<Clinic>> {
    return this.http.get(`${this.apiUrlV2}/clinic/${id}`)
      .pipe(
        map(result => this.jsonaService.deserialize(result))
      );
  }
}
