import {Component, DestroyRef, OnInit} from '@angular/core';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {NgSelectModule} from "@ng-select/ng-select";
import {mergeMap} from 'rxjs/operators';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

import {Patient} from "../../../../../core/interfaces/patient.interface";
import {LanguagesConst} from "../../constants/languages.const";
import {ProfileService} from "../../../../../dashboard/profile/profile.service";
import {TranslateModule} from "../../../../../core/pipes/translate/translate.module";

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  standalone: true,
  imports: [
    NgSelectModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  styleUrls: ['./language-select.component.scss']
})
export class LanguageSelectComponent implements OnInit {
  languages = LanguagesConst;

  lang = new FormControl('');
  profile: Patient;

  constructor(
    private profileService: ProfileService,
    private translateService: TranslateService,
    private destroyRef: DestroyRef
  ) {
  }

  ngOnInit(): void {
    this.profileService.language$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(res => {this.lang.patchValue(res)});
  }

  changeLanguage(event): void {
    this.translateService.setDefaultLang(event.language);

    this.profileService.patchLanguage(event.language)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        mergeMap(() => this.profileService.loadProfile())
      )
      .subscribe();
  }
}
