import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {filter, mergeMap} from 'rxjs/operators';
import {Observable} from 'rxjs';

import {WalletService} from '@core/services/wallet.service';
import {WalletInterface} from '@core/interfaces/wallet/wallet.interface';
import {
  ModalCostComponent
} from '../../payment/components/modal-cost/modal-cost.component';
import {ModalInfoService} from '@core/services/modal-info.service';
import {
  ModalRefillComponent
} from '../../payment/components/modal-refill/modal-refill.component';
import {ModalCostInterface} from '../../payment/interfaces/modal-cost.interface';
import {PaymentStatusesEnum} from '../../payment/enums/payment-statuses.enum';
import {ModalImagesEnum} from '@core/enums/modal-images.enum';
import {NotificationsService} from '@shared-modules/notifications/services/notifications.service';


@Injectable({
  providedIn: 'root'
})


export class ResolvePaymentService {
  dialogRefSub;
  getWalletSub;

  constructor(
    public dialog: MatDialog,
    private http: HttpClient,
    private walletService: WalletService,
    private modalInfoService: ModalInfoService,
    private notificationsService: NotificationsService
  ) {
  }

  resolvePayment(costParams: ModalCostInterface, successCallback?: () => void): void {
    this.getWalletSub?.unsubscribe();

    this.getWalletSub = this.walletService.getWallet()
      .subscribe(
        (wallet: WalletInterface) => {
          if (costParams.price > wallet.amount) {
            this.refillWallet(costParams.price - wallet.amount);
            return;
          }

          this.openCostModal(costParams, successCallback);
        },
        (error) => this.modalInfoService.onError(error)
      );
  }

  refillWallet(amount?: number): Observable<any> {
    const dialogRef = this.dialog.open(ModalRefillComponent, {
      data: {amount},
      panelClass: ['primary-modal'],
      autoFocus: false
    });

    return dialogRef.afterClosed();
  }

  openCostModal(costParams: ModalCostInterface, successCallback?: () => void): void {
    this.dialogRefSub?.unsubscribe();

    const dialogRef = this.dialog.open(ModalCostComponent, {
      data: {
        price: costParams.price,
        btnText: costParams.btnText,
      },
      panelClass: ['primary-modal'],
      autoFocus: false
    });

    this.dialogRefSub = dialogRef.afterClosed()
      .pipe(
        filter((result) => result),
        mergeMap(() => this.walletService.getWallet())
      )
      .subscribe((wallet: WalletInterface) => {
        if (costParams.price > wallet.amount) {
          this.refillWallet(costParams.price - wallet.amount);
          return;
        }

        if (successCallback) {
          successCallback();
        }
      });
  }

  showPaymentStatus(type): void {
    this.notificationsService.notificationsUpdated$.next(true);
    if (type === PaymentStatusesEnum.Failed) {
      this.onPaymentFailed();
      return;
    }

    this.onPaymentSuccess();
  }

  onPaymentSuccess(): void {
    this.walletService.walletUpdated$.next(true);

    this.modalInfoService.onSuccess(
      'Ваш платіж зараховано на рахунок',
      null,
      null,
    );
  }

  onPaymentFailed(): void {
    this.modalInfoService.onError(
      {
        customClientError: true,
        customClientErrorMessage: 'Спроба поповнення рахунку була невдалою. Будь ласка, спробуйте пізніше'
      },
      ModalImagesEnum.attention,
      null
    );
  }
}
