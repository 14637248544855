import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {combineLatest} from 'rxjs';
import {filter} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

import {Notification} from '../../../notifications/interfaces/notification.interface';
import {Patient} from '@core/interfaces/patient.interface';
import {ProfileService} from '@profile/profile.service';
import {
  prepareNewNotification
} from '../../../notifications/utils/prepare-notification';
import {NotificationTypesEnum} from '../../../notifications/enums/notification-types.enum';
import {NotificationsService} from '../../../notifications/services/notifications.service';
import {ResolvePaymentService} from '@shared-modules/payment/services/resolve-payment.service';
import {CentrifugoSocketService} from "@core/services/centrifugo-socket.service";
import {CentrifugoSocketChannelsEnum} from "@core/enums/centrifugo-socket-channels.enum";
import {logNotificationSubInfo, logSocketSubInfo} from "@core/utils/socket-logger";
import {MessagesCountsService} from "@core/services/messages-counts.service";
import {NearestConsultationService} from "@core/services/nearest-consultation.service";

@UntilDestroy()

@Component({
  selector: 'app-new-notification-listener',
  templateUrl: './new-notification-listener.component.html',
  styleUrls: ['./new-notification-listener.component.scss']
})
export class NewNotificationListenerComponent implements OnInit, OnDestroy {
  @Output() clicked: EventEmitter<null> = new EventEmitter();

  socketClient: any;
  socket: any;

  profile: Patient;
  notificationChannel: string;

  newNotification: Notification | null;

  constructor(
    private profileService: ProfileService,
    private router: Router,
    private notificationsService: NotificationsService,
    private nearestConsultationService: NearestConsultationService,
    private resolvePaymentService: ResolvePaymentService,
    private centrifugoSocketService: CentrifugoSocketService,
    private messagesCountsService: MessagesCountsService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.handleMainSubjects();
  }

  ngOnDestroy(): void {
    this.unsubscribeFromNotificationChannel();
    this.onCloseNewNotification();
  }

  showNewNotification(notification): void {
    this.newNotification = {...prepareNewNotification(notification, this.translateService.getDefaultLang())};
  }

  onCloseNewNotification(): void {
    this.newNotification = null;
  }

  private handleMainSubjects(): void {
    combineLatest([
      this.centrifugoSocketService.socketConnectionSuccess$.pipe(filter((r) => r)),
      this.profileService.profile$.pipe(filter((profile) => !!profile))
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([socket, profile]) => {
        this.socketClient = socket;
        this.profile = profile;

        this.notificationChannel = `${CentrifugoSocketChannelsEnum.NOTIFICATION}${this.profile.id}`;

        this.subscribeOnNotificationChannel();
      });
  }

  private subscribeOnNotificationChannel(): void {
    this.socket = this.socketClient.getSubscription(this.notificationChannel) || this.socketClient.newSubscription(this.notificationChannel);

    this.socket
      .on('subscribing', (ctx) => {
        logSocketSubInfo(`${ctx.code}: ${ctx.reason}`);
      })
      .on('subscribed', (ctx) => {
        logSocketSubInfo(ctx.channel);
      })
      .on('unsubscribed', (ctx) => {
        logSocketSubInfo(`${ctx.code}: ${ctx.reason}`);
      })
      .on('publication', (ctx) => {
        this.handleIncomingSocketMessage(ctx.data);
      })
      .subscribe();
  }

  private handleIncomingSocketMessage(message): void {
    logNotificationSubInfo(message);

    if (message.type === NotificationTypesEnum.NewDiscussionMessage) {
      this.router.url.includes('/dialogues')
        ? this.notificationsService.newDiscussionNotification$.next(message)
        : this.messagesCountsService.getUnreadMessages().pipe(untilDestroyed(this)).subscribe();

      if (message?.initiator_id === this.profile.id || this.router.url.includes(`dialogues/${message.initiator_id}/${message.discussion_id}`)) {
        return;
      }
    }

    if (message.type === NotificationTypesEnum.AddMoney || message.type === NotificationTypesEnum.PaymentFail) {
      this.resolvePaymentService.showPaymentStatus(message.type);
      return;
    }

    if (message.type === NotificationTypesEnum.CancelConsultationByDoctor) {
      this.nearestConsultationService.updateNearestConsultation$.next(true);
      return;
    }

    this.onCloseNewNotification();

    setTimeout(() => {
      this.showNewNotification(message);
      this.notificationsService.notificationsUpdated$.next(true);
    }, 500);
  }

  private unsubscribeFromNotificationChannel(): void {
    this.socket?.unsubscribe();
    this.socket?.removeAllListeners();
  }
}
