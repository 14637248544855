<div class="modal-wrap">
  <app-modal-header
    [title]="title"
    [cross]="true"
    (close)="dialogRef.close()"
  ></app-modal-header>

  <div class="dialog-content">
    <ng-container>
      <app-profile-pre-name
        *ngIf="!profile.name"
        [btnText]="(data.btnText | translate) || ('Почати' | translate)"
        (requestResolved)="prenameResolve($event)"
      ></app-profile-pre-name>

      <ng-container *ngIf="hasPayment && profile.name">
        <p class="text-center">
          {{ 'З вашого рахунку буде списано' | translate }} {{ data.price }} {{ 'балів' | translate }}
        </p>
      </ng-container>

      <mat-dialog-actions *ngIf="profile.name">
        <button class="btn btn-primary" [mat-dialog-close]="true">
          {{ (data.btnText | translate) || ('Почати' | translate) }}
        </button>
      </mat-dialog-actions>
    </ng-container>
  </div>
</div>
