import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {Notification} from '../../interfaces/notification.interface';
import {newNotificationCloseTimeout} from '../../../notifications/constants/notifications.const';
import {
  NotificationDeleteFilter
} from '../../../notifications/interfaces/notification-filter.interface';
import {NotificationsService} from '../../../notifications/services/notifications.service';
import {NotificationTypesEnum} from '../../../notifications/enums/notification-types.enum';
import {WalletService} from '@core/services/wallet.service';
import {ConsultationStatusesEnum} from '@consultations/enums/consultation-statuses.enum';
import {ConsultationsService} from '@consultations/consultations.service';
import {ResolvePaymentService} from '../../../payment/services/resolve-payment.service';
import {UserIdTypesEnum} from '@core/enums/user-types.enum';

@UntilDestroy()

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnChanges {
  @Input() notification: Notification;

  @Input() isNewNotification = false;

  @Output() closeNewNotification: EventEmitter<null> = new EventEmitter<null>();

  @Output() action: EventEmitter<null> = new EventEmitter<null>();

  isInitiatorAdmin = false;

  hideInitiatorAvatar = false;

  newNotificationTimeout: ReturnType<typeof setTimeout>;

  private incomingNotificationActionHandlers = {
    [NotificationTypesEnum.SubscribeWaitingArea]: this.onWaitingAreaEvent,
    [NotificationTypesEnum.UnsubscribeWaitingArea]: this.onWaitingAreaEvent,
    [NotificationTypesEnum.AddMoney]: this.onAddMoneyEvent
  };

  constructor(
    private notificationsService: NotificationsService,
    private walletService: WalletService,
    private consultationsService: ConsultationsService,
    private router: Router,
    private paymentService: ResolvePaymentService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isNewNotification && changes.isNewNotification.currentValue) {
      this.clearNotificationTimeout();

      this.newNotificationTimeout = setTimeout(
        () => this.onCloseNewNotification(),
        newNotificationCloseTimeout
      );
    }
  }

  ngOnInit(): void {
    this.isInitiatorAdmin = this.notification?.initiator?.type === UserIdTypesEnum.Admin;

    this.checkIsMedication(this.notification.type);
  }

  hideNotification(notificationId: number): void {
    this.isNewNotification
      ? this.onCloseNewNotification()
      : this.removeNotification({ids: [notificationId]});
  }

  handleNotificationAction(): void {
    if (this.incomingNotificationActionHandlers[this.notification.type]) {
      this.incomingNotificationActionHandlers[this.notification.type].call(this, this.notification);
      return;
    }

    this.navigateByRoute(this.notification.action.target);
  }

  private removeNotification(params: NotificationDeleteFilter): void {
    this.notificationsService.removeNotifications(params)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.notificationsService.notificationsUpdated$.next(true));
  }

  private onCloseNewNotification(): void {
    this.closeNewNotification.emit();

    this.clearNotificationTimeout();
  }

  private clearNotificationTimeout(): void {
    if (this.newNotificationTimeout) {
      clearTimeout(this.newNotificationTimeout);
    }
  }

  private onAddMoneyEvent(): void {
    this.walletService.walletUpdated$.next(true);
  }


  private onWaitingAreaEvent(notification: Notification): void {
    this.consultationsService
      .getConsultationById(Number(notification.appointmentId), {})
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        if (result.data.status === ConsultationStatusesEnum.Prepare) {
          this.paymentService.resolvePayment(
            {price: result.data.price},
            this.navigateByRoute.bind(this, notification.action.target)
          );

          return;
        }
        this.navigateByRoute(notification.action.target);
      });
  }

  private navigateByRoute(route: string): void {
    this.router.navigateByUrl('/', {skipLocationChange: true})
      .then(() => this.router.navigate([route]));
  }

  private checkIsMedication(type): void {
    this.hideInitiatorAvatar = type === NotificationTypesEnum.AddMedicationSchedule || type === NotificationTypesEnum.TimeToMedicationPoint;
  }
}
