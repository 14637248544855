import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ModalFindDoctorComponent} from './modal-find-doctor.component';
import {DoctorSearchModule} from '../../doctor-search/doctor-search.module';
import {
  ModalDoctorsFilterComponent
} from './components/modal-doctors-filter/modal-doctors-filter.component';
import {
  DoctorsSpecializationsComponent
} from './components/doctors-specializations/doctors-specializations.component';
import {NgSelectModule} from "@ng-select/ng-select";
import {SharedModule} from "../../../shared/shared.module";
import {SpinnerModule} from "@shared-modules/spinner/spinner.module";
import {RatingViewModule} from "@shared-modules/rating-view/rating-view.module";
import {TranslateModule} from "@core/pipes/translate/translate.module";


@NgModule({
    declarations: [
        ModalFindDoctorComponent,
        ModalDoctorsFilterComponent,
        DoctorsSpecializationsComponent
    ],
    exports: [
        DoctorsSpecializationsComponent
    ],
    imports: [
        CommonModule,
        DoctorSearchModule,
        NgSelectModule,
        SharedModule,
        SpinnerModule,
        RatingViewModule,
        TranslateModule
    ]
})
export class ModalFindDoctorModule {
}
