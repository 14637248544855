import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';

import {ModalHeaderComponent} from './modal-header.component';
import {TranslateModule} from '@core/pipes/translate/translate.module';
import {IsArrayModule} from "@core/pipes/is-array/is-array.module";

@NgModule({
  declarations: [ModalHeaderComponent],
    imports: [CommonModule, MatIconModule, TranslateModule, IsArrayModule],
  exports: [ModalHeaderComponent]
})
export class ModalHeaderModule {
}
