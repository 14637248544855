import {MatPaginatorIntl} from '@angular/material/paginator';
import {DestroyRef, Injectable} from '@angular/core';
import {ProfileService} from "@profile/profile.service";
import {TranslateService} from "@ngx-translate/core";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Injectable({
  providedIn: 'root'
})
export class CustomPaginatorIntl extends MatPaginatorIntl {
  label = 'з';

  constructor(
    private profileService: ProfileService,
    private translateService: TranslateService,
    private destroyRef: DestroyRef
  ) {
    super();

    this.profileService.language$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.setTranslations());


    // @ts-ignore
    this.getRangeLabel = (page, pageSize, length) => {
      if (length === 0 || pageSize === 0) {
        return `0 ${this.label} ` + length;
      }
      length = Math.max(length, 0);

      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} ${this.label} ${length}`;
    };

    this.changes.next();
  }

  private setTranslations(): void {
    this.translateService.get([
      'Елементів на сторінці:',
      'Далi',
      'Назад',
      'з'
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(translations => {
        this.itemsPerPageLabel = translations['Елементів на сторінці:']
        this.nextPageLabel = translations['Далi'];
        this.previousPageLabel = translations['Назад'];
        this.label = translations['з'];

        this.changes.next();
      })
  }
}
