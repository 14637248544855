import {environment} from "../../../environments/environment";

export function logSocketInfo(str): null | void {
  if (environment.loggerConfig.socketInfo) {
    return console.info('%cSocket', 'color: blue;', str);
  }

  return null;
}

export function logSocketSubInfo(str): null | void {
  if (environment.loggerConfig.socketInfo) {
    return console.info('%cSocket', 'color: green;', str);
  }

  return null;
}

export function logWaitingAreaSubInfo(message): void {
  if (environment.loggerConfig.waitingAreaInfo) {
    console.info('%cWaiting Area message', 'color: Olive;', message);
  }
}

export function logNotificationSubInfo(message): void {
  if (environment.loggerConfig.notificationInfo) {
    console.info('%cNotification message', 'color: CadetBlue;', message);
  }
}

export function logSocketError(frame): void {
  if (environment.loggerConfig.socketError) {
    console.error('Broker reported error: ', frame.headers);
    console.error('Additional details: ', frame.body);
  }
}

export function logOnlineChatSubInfo(message): void {
  if (environment.loggerConfig.onlineChatInfo) {
    console.info('%cOnline chat message', 'color: indigo;', message);
  }
}

export function logSearchDoctorSubInfo(message): void {
  if (environment.loggerConfig.searchDoctorInfo) {
    console.info('%cSearch Doctor message', 'color: SeaGreen;', message);
  }
}


export function logMediaChatSubError(target: string, error): void {
  if (environment.loggerConfig.mediaChatError) {
    console.error(`MediaChat Error from ${target}: `, error);
  }
}

export function logMediaChatSubInfo(target: string, message): void {
  if (environment.loggerConfig.mediaChatInfo) {
    console.info(
      `%cMediaChat Event %cfrom ${target}: `,
      'color: purple;',
      'color: back;',
      message
    );
  }
}

export function logDialogueSubInfo(title: number, message: any): void {
  if (environment.loggerConfig.dialogueInfo) {
    console.info(`%cDialogue ${title}`, 'color: LightBlue;', message);
  }
}
