import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {SafeUrl} from '@angular/platform-browser';
import {BehaviorSubject, Subscription} from 'rxjs';

import {unsubscribe} from '@core/utils/unsubscriber';
import {FileUploaderService} from '@core/services/file-uploader.service';
import {SafeImageService} from '../safe-image/safe-image.service';

@Component({
  selector: 'app-safe-image',
  templateUrl: './safe-image.component.html'
})
export class SafeImageComponent implements OnChanges, OnDestroy {
  imageUrl$ = new BehaviorSubject<string | SafeUrl | null>('/assets/images/preloader.svg');

  @Input() name: string;
  @Input() storage: string;
  @Input() default = 'default-avatar.png';

  private subscriptions: Subscription[] = [];

  constructor(
    private fileUploaderService: FileUploaderService,
    private safeImageService: SafeImageService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.name?.currentValue) {
      this.getImage();
    }
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  private getImage(): void {
    const candidate = this.safeImageService.getImageFromStore(this.name);

    candidate ? this.imageUrl$.next(candidate.value) : this.uploadImage();
  }

  private uploadImage(): void {
    const buildSub = this.fileUploaderService
      .buildImageByToken(this.name, this.storage)
      .subscribe(
        (result: SafeUrl) => {
          this.imageUrl$.next(result);

          this.safeImageService.setImageToStore({
            name: this.name,
            storage: this.storage,
            value: result
          });
        },
        () => this.imageUrl$.next(null)
      );
    this.subscriptions.push(buildSub);
  }
}
