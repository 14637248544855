import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {filter} from 'rxjs/operators';
import {combineLatest, Subscription} from 'rxjs';

import {unsubscribe} from '@core/utils/unsubscriber';
import {NetworkStateTypesEnum} from '@shared-modules/network-state/network-state-types.enum';
import {NetworkStateService} from '@shared-modules/network-state/network-state.service';
import {CentrifugoSocketService} from "@core/services/centrifugo-socket.service";

@Component({
  selector: 'app-my-network-error',
  templateUrl: './my-network-error.component.html',
  styleUrls: ['./my-network-error.component.scss']
})
export class MyNetworkErrorComponent implements OnInit, OnDestroy {
  chatId: string;
  isActiveReconnectBtn = window.navigator.onLine;

  private subscriptions: Subscription[] = [];

  constructor(
    private centrifugoSocketService: CentrifugoSocketService,
    private networkStateService: NetworkStateService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.handleOnNetworkAndSocketConnection();
    this.handleRouteSubject();
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  private handleRouteSubject(): void {
    const routeParamsSub = this.route.params
      .pipe(filter((params) => params?.id))
      .subscribe((params: Params) => (this.chatId = params.id));
    this.subscriptions.push(routeParamsSub);
  }

  private handleOnNetworkAndSocketConnection(): void {
    const networkAndSocketSub = combineLatest([
      this.centrifugoSocketService.socketConnectionSuccess$.pipe(filter((c: any) => c)),
      this.networkStateService.networkConnectionState$.pipe(filter((event: string | null) => !!event))
    ]).subscribe(([socket, network]) => {
      this.isActiveReconnectBtn = socket && network === NetworkStateTypesEnum.Online;
    });
    this.subscriptions.push(networkAndSocketSub);
  }
}
