import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'imagePath'
})
export class ImagePathPipe implements PipeTransform {

  transform(image: string): string {
    let host;

    switch (true) {
      case window.location.host.includes('localhost'):
        host = 'patient.doctors4ua.com';
        break;
      case window.location.host.includes('patient-dev'):
        host = host = 'patient.doctors4ua.com';
        break;
      case window.location.host.includes('patient.bekey.io'):
        host = 'patient.doctorkharkovkids.com.ua'; //patient.your-baby.com.ua
        break;
      case window.location.host.includes('patientsomnycare.bekey.io'):
        host = 'patient.omnycare.com';
        break;
      default:
        host = window.location.host;
    }

    return `/assets/clinics/${host}/images/${image}`;
  }
}
