<div class="recipes card">
  <div class="recipes_table">
    <table mat-table [dataSource]="consultations" *ngIf="!isLoading && consultations.length; else empty">
      <!-- doctor -->
      <ng-container matColumnDef="doctor">
        <th mat-header-cell *matHeaderCellDef>{{ 'Лікар' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <div class="info">
            <div class="specializations">
              <span *ngFor="let spec of element?.doctor?.specializations; let last = last">
                {{ last ? spec.name : spec.name + ', ' }}
              </span>
            </div>

            <div class="name">
              {{ element?.doctor?.surname }}
              {{ element?.doctor?.name }}
              {{ element?.doctor?.middle_name }}
            </div>
          </div>
        </td>
      </ng-container>

      <!-- diagnosis -->
      <ng-container matColumnDef="diagnosis">
        <th mat-header-cell *matHeaderCellDef>{{ 'Діагноз' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.appointment_result?.diagnosis }}
        </td>
      </ng-container>

      <!-- recommendations -->
      <ng-container matColumnDef="recommendations">
        <th mat-header-cell *matHeaderCellDef> {{ 'Загальні рекомендації' | translate }} </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.appointment_result?.recommendations }}
        </td>
      </ng-container>

      <!-- date -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>{{ 'Дата' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{element?.start_time | date:'dd MMMM YYYY':null:(lang$ | async)}}
        </td>
      </ng-container>

      <!-- medication -->
      <ng-container matColumnDef="medication">
        <th mat-header-cell *matHeaderCellDef>{{ 'Лікування' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <div class="files">
            <div
              class="files_item"
              *ngIf="element?.consultationPdf?.treatmentStorage"
              (click)="previewFile(element?.consultationPdf?.name, element?.consultationPdf?.treatmentStorage)"
            >
              <mat-icon>insert_drive_file</mat-icon>
              <span>{{ 'Лікування' | translate }}</span>
            </div>

            <div
              class="files_item"
              *ngIf="element?.consultationPdf?.directionStorage"
              (click)="previewFile(element?.consultationPdf?.name, element?.consultationPdf?.directionStorage)"
            >
              <mat-icon>insert_drive_file</mat-icon>
              <span>{{ 'Направлення' | translate }}</span>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <app-spinner [isLoading]="isLoading"></app-spinner>

    <ng-template #empty>
      <div class="recipes_empty" *ngIf="!isLoading">
        <img [src]="'wait_doctor.svg' | imagePath" alt="wait">

        <div class="recipes_desc">
          {{
          'У вас ще немає заключень лікарів і призначених рецептів' | translate
          }}
        </div>
      </div>
    </ng-template>
  </div>
</div>
