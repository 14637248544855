import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';

import {ModalRefillComponent} from './components/modal-refill/modal-refill.component';
import {ModalHeaderModule} from '../modals/modal-header/modal-header.module';
import {ModalCostComponent} from './components/modal-cost/modal-cost.component';
import {ValidatorMessageModule} from '../validator-message/validator-message.module';
import {NumbersOnlyModule} from '@core/directives/numbers-only/numbers-only.module';
import {ProfilePreNameModule} from '../profile-pre-name/profile-pre-name.module';
import {TranslateModule} from '@core/pipes/translate/translate.module';
import {
  PaymentSafaricomComponent
} from './components/payment-systems/payment-safaricom/payment-safaricom.component';
import {SharedModule} from '../../shared/shared.module';


@NgModule({
  declarations: [
    ModalRefillComponent,
    ModalCostComponent,
    PaymentSafaricomComponent,
  ],
  imports: [
    CommonModule,
    ModalHeaderModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    ValidatorMessageModule,
    MatInputModule,
    NumbersOnlyModule,
    ProfilePreNameModule,
    TranslateModule,
    NgSelectModule,
    SharedModule,
    NgxIntlTelInputModule
  ]
})
export class PaymentModule {
}
