<div class="modal-wrap">
  <div class="text-center modal-header">
    <a
      class="download"
      *ngIf="!isLoading && file"
      [href]="isPDFFile ? file?.secureUrl : file.url"
      download="document"
    >
      <mat-icon>save_alt</mat-icon>
    </a>

    <mat-icon (click)="dialogRef.close()">close</mat-icon>
  </div>

  <div class="modal_head" *ngIf="data.title">
    {{ data.title | translate }}
  </div>

  <div class="dialog-content">
    <div [class.d-none]="isLoading && !file">
      <ng-container *ngIf="isPDFFile; else image">
        <pdf-viewer
          [src]="file?.url"
          [render-text]="true"
          (after-load-complete)="documentLoaded()"
        ></pdf-viewer>
      </ng-container>

      <ng-template #image>
        <img *ngIf="file?.url" [src]="file.url" alt="Clinic"/>
      </ng-template>
    </div>

    <app-spinner [isLoading]="isLoading" [spinnerHeight]="120"></app-spinner>

    <div class="error" *ngIf="!isLoading && isError">
      {{ 'Неможливо завантажити файл. Спробуй пізніше' | translate }}
    </div>
  </div>
</div>
