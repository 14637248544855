import {Injectable} from '@angular/core';
import {logSocketError, logSocketInfo} from "../utils/socket-logger";
import {Centrifuge} from "centrifuge";
import {BehaviorSubject} from "rxjs";

import {ProfileService} from "../../dashboard/profile/profile.service";
import {environment} from "../../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class CentrifugoSocketService {
  socketClient: any;
  socketConnectionSuccess$: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);

  constructor(private profileService: ProfileService) {
  }

  socketInit(): void {
    this.socketClient = new Centrifuge(environment.socketApi, {
      data: {
        debug: true,
        token: this.profileService.getToken().replace('Bearer ', ''),
        minReconnectDelay: 2000,
        maxReconnectDelay: 3000,
        maxServerPingDelay: 100
      }
    });

    this.socketClient
      .on('connected', (ctx) => {
        logSocketInfo(`${ctx.transport} connected!`);
        this.socketConnectionSuccess$.next(this.socketClient);
      })
      .on('disconnected', (ctx) => {
        logSocketInfo(ctx.reason);
        this.socketConnectionSuccess$.next(null);
      })
      .on('error', (ctx) => {
        this.socketConnectionSuccess$.next(null);
        logSocketError(ctx);
      })
      .connect();
  }


  socketDisconnect() {
    if (this.socketClient) {
      this.socketClient.disconnect();
      logSocketInfo('websocket disconnected!');
      this.socketConnectionSuccess$.next(null);
    }
  }
}
