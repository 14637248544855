import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {WindowSizeService} from '@core/services/window-size.service';
import {SpecializationService} from '@core/services/specialization.service';
import {WindowSizeConfigInterface} from '@core/interfaces/window-size-config.interface';
import {ProfileService} from '@profile/profile.service';
import {PaymentService} from '@shared-modules/payment/services/payment.service';


@UntilDestroy()

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() showNav: boolean;
  @Output() toggleSidenav: EventEmitter<null> = new EventEmitter();

  hasPayment$ = this.paymentService.hasPayment$;

  windowSizeConfig: WindowSizeConfigInterface;

  constructor(
    private router: Router,
    private specializationService: SpecializationService,
    private windowSizeService: WindowSizeService,
    private profileService: ProfileService,
    private paymentService: PaymentService
  ) {
    this.handleWindowSizeConfig();
  }

  private handleWindowSizeConfig(): void {
    this.windowSizeService.windowSizeConfig$
      .pipe(untilDestroyed(this))
      .subscribe((windowSizeConfig: WindowSizeConfigInterface) => (
        this.windowSizeConfig = windowSizeConfig
      ));
  }
}
