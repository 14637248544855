import {Injectable} from '@angular/core';
import Jsona from 'jsona';

import {ApiJsonBodyInterface, DeserializedJsonInterface} from '../interfaces/jsona.interface';


@Injectable({
  providedIn: 'root'
})
export class JsonaService {
  private dataFormatter: Jsona = new Jsona();

  deserialize<T>(body: ApiJsonBodyInterface): DeserializedJsonInterface<T> {
    return {
      data: this.dataFormatter.deserialize(body, {preferNestedDataFromData: true}) as T,
      meta: body.meta
    };
  }
}
