import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {
  ModalDoctorScheduleComponent
} from '@shared-modules/modals/modal-doctor-schedule/modal-doctor-schedule.component';
import {DoctorWorkPointsModule} from '@shared-modules/doctor-work-points/doctor-work-points.module';
import {SafeImageModule} from '@shared-modules/safe-image/safe-image.module';
import {TranslateModule} from '@core/pipes/translate/translate.module';
import {AddressByIdModule} from '@core/pipes/address-by-id/address-by-id.module';


@NgModule({
  declarations: [
    ModalDoctorScheduleComponent
  ],
  imports: [
    CommonModule,
    DoctorWorkPointsModule,
    SafeImageModule,
    TranslateModule,
    AddressByIdModule
  ]
})
export class ModalDoctorScheduleModule {
}
