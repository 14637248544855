import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';

import {CalendarComponent} from './calendar.component';
import {CalendarHeaderComponent} from './calendar-header/calendar-header.component';
import {CustomDateAdapter} from '../../shared/custom-date-adapter';
import {CUSTOM_FORMATS} from '../../app.constants';
import {CalendarService} from './calendar.service';

@NgModule({
  declarations: [
    CalendarComponent,
    CalendarHeaderComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatDatepickerModule
  ],
  providers: [
    CalendarService,
    {provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_LOCALE, useValue: 'uk'},
    {provide: MAT_DATE_FORMATS, useValue: CUSTOM_FORMATS}
  ],
  exports: [
    CalendarComponent,
    CalendarHeaderComponent
  ]
})
export class CalendarModule {
}
