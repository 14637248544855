import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';

import {DoctorSearchComponent} from './doctor-search.component';
import {ValidatorMessageModule} from '../validator-message/validator-message.module';
import {TranslateModule} from '@core/pipes/translate/translate.module';

@NgModule({
  declarations: [DoctorSearchComponent],
  imports: [
    CommonModule,
    ValidatorMessageModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    TranslateModule
  ],
  exports: [DoctorSearchComponent]
})
export class DoctorSearchModule {
}
