import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'isArray'
})
export class IsArrayPipe implements PipeTransform {

  transform(value: string | string[],): boolean {
    return Array.isArray(value);
  }
}
