import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatBadgeModule} from '@angular/material/badge';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';

import {
  NotificationsListComponent
} from './components/notifications-list/notifications-list.component';
import {NotificationComponent} from './components/notification/notification.component';
import {SafeImageModule} from '../safe-image/safe-image.module';
import {ConsultationsService} from '@consultations/consultations.service';
import {PaymentModule} from '../payment/payment.module';
import {
  NewNotificationListenerComponent
} from './components/new-notification-listener/new-notification-listener.component';
import {DateAsAgoModule} from '@core/pipes/date-as-ago/date-as-ago.module';
import {TranslateModule} from '@core/pipes/translate/translate.module';
import {IsArrayModule} from '@core/pipes/is-array/is-array.module';


@NgModule({
  declarations: [
    NotificationsListComponent,
    NotificationComponent,
    NewNotificationListenerComponent
  ],
  imports: [
    CommonModule,
    SafeImageModule,
    RouterModule,
    PaymentModule,
    MatIconModule,
    MatButtonModule,
    MatBadgeModule,
    MatMenuModule,
    MatPaginatorModule,
    DateAsAgoModule,
    TranslateModule,
    IsArrayModule
  ],
  exports: [
    NotificationsListComponent,
    NewNotificationListenerComponent
  ],
  providers: [
    ConsultationsService
  ]
})
export class NotificationsModule {
}
