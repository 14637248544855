<div class="header">
  <div class="header_container">
    <app-logo></app-logo>

    <div class="header_nav" *ngIf="!showNav && windowSizeConfig.isNoteBookDesktop">
      <app-link-doctors></app-link-doctors>

      <app-link-consultations></app-link-consultations>

      <app-link-tracker></app-link-tracker>

      <app-link-recipes></app-link-recipes>

      <app-link-measurements></app-link-measurements>
    </div>

    <div class="header_info">
      <div class="header_item lang" *ngIf="windowSizeConfig.isTablet">
        <app-language-select></app-language-select>
      </div>

      <div class="header_item wallet" *ngIf="(hasPayment$ | async) && windowSizeConfig.isNoteBookDesktop">
        <app-wallet></app-wallet>
      </div>
      <div class="header_item">
        <app-link-dialogues></app-link-dialogues>
      </div>
      <div class="header_item">
        <app-notifications-list></app-notifications-list>
      </div>
      <div class="header_item profile">
        <app-profile-header></app-profile-header>
      </div>
      <div *ngIf="!windowSizeConfig.isNoteBookDesktop" class="header_item sidenav-toggle">
        <button mat-icon-button (click)="toggleSidenav.emit()">
          <mat-icon>menu</mat-icon>
        </button>
      </div>

    </div>
  </div>
</div>
