import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {filter} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {NotificationsService} from '../../services/notifications.service';
import {Notification} from '../../interfaces/notification.interface';
import {ApiListFilter} from '@core/interfaces/list-filter.interface';
import {ApiListResponse} from '@core/interfaces/list-response.interface';

@UntilDestroy()

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent implements OnInit {
  @Output() clicked: EventEmitter<null> = new EventEmitter();

  notifications: Notification[] = [];

  params: ApiListFilter = {
    page: 1,
    perPage: 99,
    field: 'created_at',
    direction: 'DESC'
  };

  total = 0;

  constructor(private notificationsService: NotificationsService) {
  }

  ngOnInit(): void {
    this.handleNotificationUpdated();
    this.getNotifications();
  }

  getNotifications(): void {
    this.notificationsService
      .getNotifications(this.params)
      .pipe(untilDestroyed(this))
      .subscribe((result: ApiListResponse<Notification>) => {
        this.notifications = result.data;
        this.total = result.meta.total;
      });
  }

  removeAll(event: Event): void {
    event.preventDefault();

    this.notificationsService.removeNotifications({is_all: 1})
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.params.page = 1;
        this.getNotifications();
      });
  }

  onMenuClosed(): void {
    this.params.page = 1;
    this.params.perPage = 99;
  }

  onMenuOpened(): void {
    this.getNotifications();
  }

  private handleNotificationUpdated(): void {
    this.notificationsService.notificationsUpdated$
      .pipe(
        filter((event: boolean | null) => event),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.params.page = 1;
        this.getNotifications();
      });
  }
}
