import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {registerLocaleData} from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import localeUa from '@angular/common/locales/uk';
import localeEn from '@angular/common/locales/en';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {provideEnvironmentNgxMask} from 'ngx-mask';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MyNetworkErrorComponent} from './chat-errors/my-network-error/my-network-error.component';
import {
  DoctorNetworkErrorComponent
} from './chat-errors/doctor-network-error/doctor-network-error.component';
import {TokenInterceptor} from '@core/interceptors/requests.interceptor';
import {TimezoneInterceptor} from '@core/interceptors/timezine.interceptor';
import {AppLayoutModule} from '@shared-modules/layouts/app-layout/app-layout.module';
import {SharedModule} from './shared/shared.module';
import {ModalInfoModule} from '@shared-modules/modals/modal-info/modal-info.module';
import {ModalConfirmModule} from '@shared-modules/modals/modal-confirm/modal-confirm.module';
import {SpinnerModule} from '@shared-modules/spinner/spinner.module';
import {RecaptchaInterceptor} from '@core/interceptors/recaptcha.interceptor';
import {environment} from '../environments/environment';
import {ModalNewUserModule} from '@shared-modules/modals/modal-new-user/modal-new-user.module';
import {ModalHeaderModule} from '@shared-modules/modals/modal-header/modal-header.module';
import {DoctorWorkPointsModule} from '@shared-modules/doctor-work-points/doctor-work-points.module';
import {
  ModalFindDoctorModule
} from '@shared-modules/modals/modal-find-doctor/modal-find-doctor.module';
import {
  ModalManageConsultationModule
} from '@shared-modules/modals/modal-manage-consultation/modal-manage-consultation.module';
import {
  ModalDoctorScheduleModule
} from '@shared-modules/modals/modal-doctor-schedule/modal-doctor-schedule.module';
import {
  ModalSearchSpecialistModule
} from '@shared-modules/modals/modal-search-specialist/modal-search-specialist.module';
import {ImagePathModule} from '@core/pipes/image-path/image-path.module';
import {
  ModalManageByCommunicationTypeModule
} from '@shared-modules/modals/modal-manage-by-communication-type/modal-manage-by-communication-type.module';

registerLocaleData(localeRu, 'ru');
registerLocaleData(localeUa, 'uk');
registerLocaleData(localeEn, 'en');

export function createTranslateLoader(http: HttpClient) {
  return  new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    MyNetworkErrorComponent,
    DoctorNetworkErrorComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    PdfViewerModule,
    AppLayoutModule,
    ModalConfirmModule,
    ModalInfoModule,
    SpinnerModule,
    RecaptchaV3Module,
    ModalNewUserModule,
    ModalHeaderModule,
    DoctorWorkPointsModule,
    ModalFindDoctorModule,
    ModalManageConsultationModule,
    ModalDoctorScheduleModule,
    ModalSearchSpecialistModule,
    ModalManageByCommunicationTypeModule,
    ImagePathModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimezoneInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RecaptchaInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'uk'
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.siteKey
    },
    provideEnvironmentNgxMask()
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
