import {PaymentSystemsEnum} from '../enums/payment-systems.enum';

export const PaymentSystemsConst = [
  {
    type: PaymentSystemsEnum.Safaricom,
    name: 'Safaricom',
    logo: './assets/images/safaricom.svg'
  },
  {
    type: PaymentSystemsEnum.Eurocom,
    name: 'Eurocom',
    logo: ''
  },
  {
    type: PaymentSystemsEnum.PayPal,
    name: 'PayPal',
    logo: ''
  },
];
