import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {
  Specialization
} from '../interfaces/specialization/specialization.interface';
import {
  GetSpecializationsParamsInterface
} from '../interfaces/specialization/specialization-params.interface';
import {getObjectUrlParams} from '../utils/filter-converter';
import {environment} from '../../../environments/environment';
import {JsonaService} from '@core/services/jsona.service';
import {DeserializedJsonInterface} from '@core/interfaces/jsona.interface';

@Injectable({
  providedIn: 'root'
})
export class SpecializationService {
  private apiUrlV2 = `${environment.apiUrlV2}/specializations`;

  constructor(
    private http: HttpClient,
    private jsonaService: JsonaService
  ) {
  }

  getSpecializationsList(filter: Partial<GetSpecializationsParamsInterface>): Observable<DeserializedJsonInterface<Specialization[]>> {
    const params = getObjectUrlParams(filter);
    return this.http.get(this.apiUrlV2, {params})
      .pipe(map(res => this.jsonaService.deserialize<Specialization[]>(res))
      );
  }

  sendSpecializationProposition(name: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrlV2}/proposition`, {name});
  }
}
