import {Component} from '@angular/core';
import {UntilDestroy} from "@ngneat/until-destroy";

import {MessagesCountsService} from "@core/services/messages-counts.service";

@UntilDestroy()
@Component({
  selector: 'app-link-dialogues',
  templateUrl: './link-dialogues.component.html',
  styleUrls: ['./link-dialogues.component.scss']
})
export class LinkDialoguesComponent {
  unreadMessagesCount$ = this.messagesCountsService.unreadMessagesCount$;

  constructor(private messagesCountsService: MessagesCountsService) {
  }
}
