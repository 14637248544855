import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgSelectModule} from '@ng-select/ng-select';

import {DoctorWorkPointsComponent} from './doctor-work-points.component';
import {TodayEventListComponent} from './today-event-list/today-event-list.component';
import {CalendarModule} from '../calendar/calendar.module';
import {SpinnerModule} from '@shared-modules/spinner/spinner.module';
import {TranslateModule} from '@core/pipes/translate/translate.module';
import {SharedModule} from '../../shared/shared.module';

@NgModule({
  declarations: [
    DoctorWorkPointsComponent,
    TodayEventListComponent
  ],
  exports: [
    DoctorWorkPointsComponent
  ],
  imports: [
    CommonModule,
    CalendarModule,
    SpinnerModule,
    TranslateModule,
    SharedModule,
    NgSelectModule
  ]
})
export class DoctorWorkPointsModule {
}
