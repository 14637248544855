import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import {MaterialModule} from './material/material.module';
import {CustomPaginatorIntl} from './material/custom-paginator-intl';
import {CustomDateAdapter} from './custom-date-adapter';
import {CUSTOM_FORMATS} from '../app.constants';
import {SafeImageModule} from '@shared-modules/safe-image/safe-image.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    MaterialModule,
    SafeImageModule,
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: CustomPaginatorIntl
    },
    {provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_LOCALE, useValue: 'uk'},
    {provide: MAT_DATE_FORMATS, useValue: CUSTOM_FORMATS}
  ],
  exports: [
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    SafeImageModule,
  ]
})
export class SharedModule {}
